import { Component, OnInit } from '@angular/core';
import { CategoriesService } from 'src/app/services/categori.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-packeg-details',
  templateUrl: './packeg-details.component.html',
  styleUrls: ['./packeg-details.component.css'],
})
export class PackegDetailsComponent implements OnInit {
  packageForm: FormGroup | any;

  constructor(
    private rest: CategoriesService,
    private route: ActivatedRoute,
    private routing: Router,
    private _sanitizer: DomSanitizer
  ) {}

  packId: any;
  packName: any;
  packImage: any;
  pack_discount: any;
  totalPrice: any;
  products: any = [];
  showProduct = true;
  product_arr: any = [];
  ngOnInit() {
    this.packId = this.route.snapshot.paramMap.get('id');
    this.getData();
    this.getPackageForm();
  }

  getData() {
    this.rest.showSpinner();
    this.rest.packDetails(this.packId).subscribe((res: any) => {
      this.packName = res.name;
      this.packImage = res.image;
      this.pack_discount = res.discount;
      this.totalPrice = res.total_price;
      this.packImage = `${environment.apiUrl}/images/?id=${res.image}`;
      // this.packImage = this._sanitizer.bypassSecurityTrustUrl(`data:image/png;base64,`+ this.packImage);

      res.ProductPackages.forEach((element: any) => {
        element.products.image[0] = `${environment.apiUrl}/images/?id=${element.products.image[0]}`;
      });

      this.product_arr = res.ProductPackages;
      if (this.product_arr.length != 0) {
        this.showProduct = true;
      } else {
        this.showProduct = false;
      }
      this.rest.hideSpinner();
    });
  }

  // editCat(){
  //   this.rest.showSpinner()
  //   this.cat_id =+ this.cat_id
  //   let obj = {
  //     id:this.cat_id,
  //     name : this.cat_name
  //   }
  //    this.rest.editCat(obj).subscribe((res :any) => {
  //     this.rest.hideSpinner()
  //     this.getData()
  //    })
  // }

  deletePackage() {
    this.rest.showSpinner();
    this.rest
      .deletePackage(this.packId)
      .then((response) => response.text())
      .then((result) => {
        this.rest.hideSpinner();
        this.routing.navigate(['main/packages']);
      })
      .catch((error) => console.log('error', error));
  }

  // addProduct (){
  //   let obj = this.productForm.value
  //   obj.category_id = this.cat_id
  //   this.rest.addProduct(obj).subscribe((res :any) => {
  //     this.product_id = res
  //     this.showUpload = true

  //   })
  // }

  getPackageForm() {
    this.packageForm = new FormGroup({
      discount: new FormControl(null),
    });
  }

  getDataForEditModel() {
    this.packageForm.patchValue({
      discount: this.pack_discount,
    });
  }

  save() {
    this.rest.showSpinner();
    let obj = {
      id: this.packId,
      discount: this.packageForm.get('discount').value,
    };

    this.rest.editPackage(obj).subscribe(
      (res: any) => {
        this.rest.hideSpinner();
        this.getData();
      },
      (err: any) => {
        this.rest.hideSpinner();
      }
    );
  }
}
