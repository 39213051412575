import { Component, OnInit } from '@angular/core';
import { CategoriesService } from 'src/app/services/categori.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-categoris',
  templateUrl: './categoris.component.html',
  styleUrls: ['./categoris.component.css'],
})
export class CategorisComponent implements OnInit {
  categoryName: string = '';
  categoryId: any;
  showUploadFile = false;
  image_file: any;
  uploadForm: FormGroup | any;
  category_arr: any = [];
  dataFound = true;
  constructor(
    private rest: CategoriesService,
    private _sanitizer: DomSanitizer,
    private route: Router,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.rest.showSpinner();
    this.uploadForm = this.formBuilder.group({
      profile: [''],
    });

    this.list_Of_cat();
  }

  // list of cat
  // list cats
  list_Of_cat() {
    this.rest.list_Of_cat().subscribe(
      (res: any) => {
        this.rest.hideSpinner();
        if (res.length != 0) {
          this.dataFound = true;
          this.category_arr = res;
          this.category_arr.forEach((element: any) => {
            if (element.image != null) {
              element.image.image_url = `${environment.apiUrl}/images/?id=${element.image.image_url}`;
            } else {
              element.image = {
                image_url: 'assets/images/rk.jpeg',
              };
            }
            // if(element.image != null){
            //   element.image.image = this._sanitizer.bypassSecurityTrustUrl(`data:image/png;base64,`+element.image.image);
            // }else {
            //   element.image = {
            //     image : "assets/images/rk.jpeg"
            //   }
            // }
          });
        } else {
          this.dataFound = false;
        }

        this.rest.hideSpinner();
      },
      (err: any) => {
        this.rest.errorToaster('something wrong happened please reload page');
        this.rest.hideSpinner();
      }
    );
  }
  submitted = 'false';
  addCategory() {
    if (this.categoryName != '') {
      this.submitted = 'false';
      let obj = {
        name: this.categoryName,
      };

      this.rest.addCategory(obj).subscribe(
        (res: any) => {
          this.categoryId = res;
          this.showUploadFile = true;
          this.categoryName = '';
        },
        (err: any) => {
          this.rest.errorToaster(err.error.message);
        }
      );
    } else {
      this.submitted = 'true';
    }
  }

  uploadFile(e: any) {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      this.uploadForm.get('profile').setValue(file);
    }
  }

  addCategoryImage() {
    this.rest.showSpinner();
    if (this.uploadForm.get('profile').value.name != undefined) {
      this.rest
        .upload_cat_image(this.categoryId, this.uploadForm.get('profile').value)
        .then((response: any) => response.text())
        .then((result: any) => {
          this.rest.successToast('Category Added Successfully');
          this.list_Of_cat();
          this.showUploadFile = false;
        })
        .catch((error: any) => {
          this.rest.errorToaster('something wrong happened please try again');
          this.rest.hideSpinner();
        });
    } else {
      this.list_Of_cat();
      this.rest.hideSpinner();
    }
  }

  cat_details(id: any) {
    this.route.navigate(['main/categroy_details', id]);
  }
}
