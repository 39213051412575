<header>
    <div class="container">
        <div class="icons">
            <button class="btn">OnLine Site</button>
            <span class="material-symbols-outlined icon">
                person
            </span>
            <span>
                New Admin
            </span>
            <span class="material-symbols-outlined icon">
                settings
            </span>
            <span class="material-symbols-outlined icon">
                notifications
            </span>
        </div>
    </div>
</header>
<hr>
