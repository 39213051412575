<section class="main">
  <div class="mb-5">
    <button
      class="me-2 mb-1"
      (click)="addData()"
      mat-raised-button
      data-bs-toggle="modal"
      data-bs-target="#editProduct"
    >
      Edit Product
    </button>
    <button
      color="warn"
      mat-raised-button
      data-bs-toggle="modal"
      data-bs-target="#DeleteProduct"
    >
      Delete Product
    </button>
  </div>

  <div class="container">
    <div class="row">
      <div class="col-12 col-md-5">
        <img class="img-fluid" [src]="imageBath" />
      </div>
      <div class="col-12 col-md-5">
        <p>Name : {{ prodObj.name }}</p>
        <p>Description :</p>
        <ul>
          <li *ngFor="let item of description">
            <span>{{ item }}</span>
          </li>
        </ul>
        <p>Price : {{ prodObj.price }}</p>
        <p>Quantity : {{ prodObj.quantity }}</p>
      </div>
    </div>
    <hr *ngIf="mainImageArr.length != 0" />
    <div class="mt-4" *ngIf="mainImageArr.length != 0">
      <h2>Main Images</h2>

      <div class="row">
        <div
          class="col-md-3 col-6 text-center"
          *ngFor="let item of mainImageArr"
        >
          <img class="img-fluid" src="{{ item.image_url }}" />
          <br />
          <button (click)="DeleteImage(item.id)" class="mt-2 btn btn-danger">
            Delete
          </button>
        </div>
      </div>
    </div>

    <hr *ngIf="screenshotArray.length != 0" />
    <div class="mt-4" *ngIf="screenshotArray.length != 0">
      <h2>screenshot</h2>

      <div class="row">
        <div
          class="col-md-3 col-6 text-center"
          *ngFor="let item of screenshotArray"
        >
          <img class="img-fluid" src="{{ item.image_url }}" />
          <br />
          <button (click)="DeleteImage(item.id)" class="mt-2 btn btn-danger">
            Delete
          </button>
        </div>
      </div>
    </div>

    <hr *ngIf="videosArr.length != 0" />
    <div class="mt-4" *ngIf="videosArr.length != 0">
      <h2>Videos</h2>

      <div class="row">
        <div class="col-12 col-md-6 text-center" *ngFor="let item of videosArr">
          <video style="width: 100%" controls>
            <source src="{{ item.image_url }}" type="video/mp4" />
            <source src="{{ item.image_url }}" type="video/ogg" />
          </video>

          <button (click)="DeleteImage(item.id)" class="mt-2 btn btn-danger">
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>
</section>

<div
  class="modal fade"
  id="editProduct"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <form [formGroup]="productForm">
          <div>
            <label>Product Name</label>
            <input formControlName="name" type="text" class="form-control" />
          </div>
          <div>
            <label>Product Price</label>
            <input formControlName="price" type="text" class="form-control" />
          </div>
          <div>
            <label>Product description</label>
            <textarea
              formControlName="description"
              type="text"
              class="form-control"
            ></textarea>
          </div>
          <div>
            <label>Product quantity</label>
            <input
              formControlName="quantity"
              type="text"
              class="form-control"
            />
          </div>
        </form>
        <hr />
        <div class="mt-3">
          <label>Edit Product Image</label>
          <input
            multiple
            accept="image/*"
            (change)="uploadFile($event, 'main')"
            type="file"
            class="form-control"
          />
        </div>
        <div class="mt-3">
          <label>Edit Screenshot</label>
          <input
            multiple
            accept="image/*"
            (change)="uploadFile($event, 'screenshot')"
            type="file"
            class="form-control"
          />
        </div>

        <div class="mt-3">
          <label>Edit video </label>
          <input
            multiple
            accept="video/mp4,video/x-m4v,video/*"
            (change)="uploadFile($event, 'video')"
            type="file"
            class="form-control"
          />
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>

          <button
            (click)="editProduct()"
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
          >
            Edit
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="DeleteProduct"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div>
          <h1>are you sure delete this product</h1>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Cancel
          </button>

          <button
            (click)="deleteProduct()"
            type="button"
            data-bs-dismiss="modal"
            class="btn btn-primary"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
