<section class="mt-5">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-3 col-xl-3">
                <div class="tab">
                    <span class="material-symbols-outlined" style="background:#29292c">
                        person
                    </span>
                    <span>Total Users</span>
                    <p>100</p>
                </div>
            </div>
            <div class="col-12 col-md-3 col-xl-3">
                    <div class="tab">
                        <span class="material-symbols-outlined" style="background:#66bb6a">
                            shopping_bag
                        </span>
                        <span>Total Orders</span>
                        <p>300</p>
                    </div>
                </div>
            <div class="col-12 col-md-3 col-xl-3">
                <div class="tab">
                    <span class="material-symbols-outlined" style="background:#ec407a">
                        person
                    </span>
                    <span>New Users</span>
                    <p>20</p>
                </div>
            </div>
            <div class="col-12 col-md-3 col-xl-3">
                <div class="tab">
                    <span class="material-symbols-outlined" style="background:#49a3f1">
                        shopping_bag
                    </span>
                    <span>New Orders</span>
                    <p>2</p>
                </div>
            </div>
        </div>
    </div>
</section>