import { Component } from '@angular/core';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent {

  menuClose = "false"

  closeMenu(){
    this.menuClose = "true"
  }

  openMenu(){
    this.menuClose = "false"
  }
}
