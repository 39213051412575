import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'E-CommerceAdmin';
  showFiller = false;

  constructor(private route: Router) {
    if (localStorage.getItem('access_token')) {
    } else {
      this.route.navigateByUrl('/login');
    }
  }
}
