<section>
    <div class="row">
        <div class="closeBtn" *ngIf="menuClose == 'false'" (click)="closeMenu()">
            <img src="assets/close.png">
        </div>
        <div class="openBtn" *ngIf="menuClose == 'true'" (click)="openMenu()">
            <img src="assets/menu.png">
        </div>
        <div class="col-3 col-md-3 col-lg-2" [ngClass]="{'sideBar': menuClose === 'true'}">
            <app-side-bar></app-side-bar>
        </div>
        <div class="col-9 col-md-9 col-lg-10" [ngClass]="{'col-12': menuClose === 'true'}">
            <app-header></app-header>
            <router-outlet></router-outlet>
        </div>
    </div>
</section>
