<section>
    <h1>
        Promo Name : {{promoName}}
    </h1>

    <hr>

    <div>
        <h3>Orders</h3>

        <div *ngIf="promoProud.length != 0" class="mt-2 table-responsive">
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Client</th>
                        <th scope="col">Total price</th>

                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of promoProud">
                        <th>{{item.id}}</th>
                        <td>{{item.client}}</td>
                        <td>{{item.total_price}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <p *ngIf="promoProud.length == 0">No Orders Found</p>
    </div>
</section>