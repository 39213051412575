import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CategoriesService {
  baseUrl = environment.apiUrl;
  // baseUrl = "https://yatty.radwakhallaf.com/api"
  auth_token: any;

  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) {}

  login(obj: any) {
    return this.http.post(`${this.baseUrl}/auth/login`, obj);
  }

  adminRegister(obj: any) {
    return this.http.post(`${this.baseUrl}/auth/register`, obj);
  }

  addCategory(obj: any) {
    this.auth_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.auth_token}`,
    });
    return this.http.post(`${this.baseUrl}/cats/`, obj, { headers: headers });
  }

  list_Of_cat() {
    this.auth_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.auth_token}`,
    });

    return this.http.get(`${this.baseUrl}/cats/`, { headers: headers });
  }
  listCatOnly() {
    this.auth_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.auth_token}`,
    });

    return this.http.get(`${this.baseUrl}/cats/listed`, { headers: headers });
  }

  cat_details(id: any) {
    this.auth_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.auth_token}`,
    });

    return this.http.get(`${this.baseUrl}/cats/detailed/${id}`, {
      headers: headers,
    });
  }

  upload_cat_image(id: any, file: any) {
    this.auth_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.auth_token}`,
    });

    var myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${this.auth_token}`);

    var formdata = new FormData();
    formdata.append('key', file, file.name);
    formdata.append('category_id', id);

    var requestOptions: any = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow',
    };

    return fetch(`${this.baseUrl}/images/cat`, requestOptions);
  }

  cat_full_details(id: any) {
    this.auth_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.auth_token}`,
    });
    let params = new HttpParams().set('id', id);

    const options = { params: params, headers: headers };
    return this.http.get(`${this.baseUrl}/cats/detailed/`, options);
  }

  editCat(obj: any) {
    this.auth_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.auth_token}`,
    });
    return this.http.put(`${this.baseUrl}/cats/`, obj, { headers: headers });
  }

  deleteCategory(id: any) {
    this.auth_token = localStorage.getItem('access_token');
    var myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${this.auth_token}`);
    myHeaders.append('Content-Type', 'application/json');
    var raw = JSON.stringify({
      id: 2,
    });
    var requestOptions: any = {
      method: 'DELETE',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };
    return fetch(`${this.baseUrl}/cats/?id=${id}`, requestOptions);
  }

  // add product

  allProduct() {
    return this.http.get(`${this.baseUrl}/products/all/`);
  }

  addProduct(obj: any) {
    this.auth_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.auth_token}`,
    });
    return this.http.post(`${this.baseUrl}/products/`, obj, {
      headers: headers,
    });
  }

  upload_product_image(id: any, file: any) {
    this.auth_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.auth_token}`,
    });

    var myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${this.auth_token}`);

    var formdata = new FormData();
    file.forEach((element: any) => {

      formdata.append(element.name, element.value, element.value.name);
    });

    formdata.append('product_id', id);

    var requestOptions: any = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow',
    };

    return fetch(`${this.baseUrl}/images/`, requestOptions);
  }

  getProduct(id: any) {
    this.auth_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.auth_token}`,
    });

    return this.http.get(`${this.baseUrl}/products/?product_id=${id}`, {
      headers: headers,
    });
  }

  editProduct(obj: any) {
    this.auth_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.auth_token}`,
    });
    return this.http.put(`${this.baseUrl}/products/`, obj, {
      headers: headers,
    });
  }

  deleteProduct(id: any) {
    this.auth_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.auth_token}`,
    });
    let params = new HttpParams().set('product_id', id);

    const options = { params: params, headers: headers };
    return this.http.delete(`${this.baseUrl}/products/`, options);
  }

  searchProd(name: any, id: any) {
    return this.http.get(
      `${this.baseUrl}/products/cat_product_search/?name=${name}&category_id=${id}`
    );
  }

  // clients

  getAllClients(page: number, limit: number, clientName?: string | any) {
    let url = `${this.baseUrl}/client/all?`;

    if (page) {
      url += `page=${page}&`;
    }
    if (limit) {
      url += `limit=${limit}&`;
    }
    if (clientName) {
      url += `name=${clientName}&`;
    }

    this.auth_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.auth_token}`,
    });

    return this.http.get(url, { headers: headers });
  }

  clientDetails(id: any) {
    this.auth_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.auth_token}`,
    });

    return this.http.get(`${this.baseUrl}/client/?client_id=${id}`, {
      headers: headers,
    });
  }

  //User's addresses
  getUserAddress(clientId: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.auth_token}`,
    });

    return this.http.get(
      `${this.baseUrl}/client/address?client_id=${clientId}`,
      {
        headers: headers,
      }
    );
  }
  //User's addresses

  //Adding new address for the user
  addNewAddress(obj: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.auth_token}`,
    });

    return this.http.post(`${this.baseUrl}/client/address`, obj, {
      headers: headers,
    });
  }
  //Adding new address for the user

  // orders

  getOrders(page: number, limit: number) {
    this.auth_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.auth_token}`,
    });

    return this.http.get(
      `${this.baseUrl}/orders/?page=${page}&limit=${limit}`,
      { headers: headers }
    );
  }

  orderDetails(id: any) {
    this.auth_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.auth_token}`,
    });
    let params = new HttpParams().set('order_id', id);

    const options = { params: params, headers: headers };
    return this.http.get(`${this.baseUrl}/orders/detailed`, options);
  }

  editOrder(obj: any) {
    this.auth_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.auth_token}`,
    });
    return this.http.put(`${this.baseUrl}/orders/`, obj, {
      headers: headers,
    });
  }

  editPackage(obj: any) {
    this.auth_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.auth_token}`,
    });

    return this.http.put(`${this.baseUrl}/packages/`, obj, {
      headers: headers,
    });
  }

  orderStatus(obj: any) {
    this.auth_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.auth_token}`,
    });

    return this.http.put(`${this.baseUrl}/orders/status`, obj, {
      headers: headers,
    });
  }

  deleteOrder(id: any) {
    this.auth_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.auth_token}`,
    });

    return this.http.delete(`${this.baseUrl}/orders/?id=${id}`, {
      headers: headers,
    });
  }

  getArchivedOrders(page: number, limit: number) {
    this.auth_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.auth_token}`,
    });

    return this.http.get(
      `${
        this.baseUrl
      }/orders/filtered?page=${page}&limit=${limit}&paid=${true}&delivered=${true}`,
      { headers: headers }
    );
  }

  getFilteredOrdersService(
    page: number,
    limit: number,
    productName?: string | any,
    phoneNumber?: number | any,
    cancelledOrder?: boolean,
    paidOrder?: boolean,
    deliveredOrder?: boolean
  ) {
    let url = `${this.baseUrl}/orders/filtered?`;

    if (productName) {
      url += `product_name=${productName}&`;
    }
    if (page) {
      url += `page=${page}&`;
    }
    if (limit) {
      url += `limit=${limit}&`;
    }
    if (phoneNumber) {
      url += `phone_number=${phoneNumber}&`;
    }
    if (cancelledOrder !== undefined) {
      url += `cancelled=${cancelledOrder}&`;
    }
    if (paidOrder !== undefined && paidOrder !== null) {
      url += `paid=${paidOrder}&`;
    }
    if (deliveredOrder !== undefined && deliveredOrder !== null) {
      url += `delivered=${deliveredOrder}&`;
    }

    this.auth_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.auth_token}`,
    });

    return this.http.get(url, { headers: headers });
  }
  // packages

  addPack(obj: any) {
    this.auth_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.auth_token}`,
    });
    return this.http.post(`${this.baseUrl}/packages/`, obj, {
      headers: headers,
    });
  }
  getPackages() {
    this.auth_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.auth_token}`,
    });
    return this.http.get(`${this.baseUrl}/packages/`, { headers: headers });
  }

  upload_pack_image(id: any, file: any) {
    this.auth_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.auth_token}`,
    });

    var myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${this.auth_token}`);

    var formdata = new FormData();
    formdata.append('key', file, file.name);
    formdata.append('package_id', id);

    var requestOptions: any = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow',
    };

    return fetch(`${this.baseUrl}/images/package`, requestOptions);
  }

  packDetails(id: any) {
    this.auth_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.auth_token}`,
    });
    return this.http.get(`${this.baseUrl}/packages/detailed?package_id=${id}`, {
      headers: headers,
    });
  }

  deletePackage(id: any) {
    this.auth_token = localStorage.getItem('access_token');
    var myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${this.auth_token}`);
    myHeaders.append('Content-Type', 'application/json');
    var raw = JSON.stringify({
      id: id,
    });
    var requestOptions: any = {
      method: 'DELETE',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };
    return fetch(`${this.baseUrl}/packages/?package_id=${id}`, requestOptions);
  }
  // toster

  successToast(name: any) {
    this.toastr.success(name, 'Great', {
      timeOut: 4000,
    });
  }

  errorToaster(name: any) {
    this.toastr.error(name, 'Opps', {
      timeOut: 4000,
    });
  }

  // spinner

  showSpinner() {
    this.spinner.show();
  }
  hideSpinner() {
    this.spinner.hide();
  }

  // order request
  orderRequest() {
    this.auth_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.auth_token}`,
    });
    return this.http.get(`${this.baseUrl}/products/request`, {
      headers: headers,
    });
  }

  // promo code

  addPromo(obj: any) {
    this.auth_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.auth_token}`,
    });
    return this.http.post(`${this.baseUrl}/promos/`, obj, { headers: headers });
  }

  editPromo(obj: any) {
    this.auth_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.auth_token}`,
    });
    return this.http.put(`${this.baseUrl}/promos/`, obj, { headers: headers });
  }

  allPromos() {
    this.auth_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.auth_token}`,
    });
    return this.http.get(`${this.baseUrl}/promos/`, { headers: headers });
  }

  deletePromo(id: any) {
    this.auth_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.auth_token}`,
    });

    return this.http.delete(`${this.baseUrl}/promos/?id=${id}`, {
      headers: headers,
    });
  }

  retrievePromo(name: any) {
    this.auth_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.auth_token}`,
    });
    return this.http.get(`${this.baseUrl}/promos/detailed?promocode=${name}`, {
      headers: headers,
    });
  }

  // blogs

  getBlogs() {
    this.auth_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.auth_token}`,
    });
    return this.http.get(`${this.baseUrl}/blogs/`, { headers: headers });
  }

  getBlogDetails(id: any) {
    this.auth_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.auth_token}`,
    });
    return this.http.get(`${this.baseUrl}/blogs/detailed?id=${id}`, {
      headers: headers,
    });
  }

  editBlog(obj: any) {
    this.auth_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.auth_token}`,
    });
    return this.http.put(`${this.baseUrl}/blogs/`, obj, { headers: headers });
  }

  addBlog(obj: any) {
    this.auth_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.auth_token}`,
    });
    return this.http.post(`${this.baseUrl}/blogs/`, obj, { headers: headers });
  }

  deleteBlog(id: any) {
    this.auth_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.auth_token}`,
    });
    return this.http.delete(`${this.baseUrl}/blogs/?id=${id}`, {
      headers: headers,
    });
  }

  // addBlogImage(id:any , file:any){
  //   this.auth_token = localStorage.getItem("access_token")
  //   const headers = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'Authorization': `Bearer ${this.auth_token}`
  //   })

  //   var myHeaders = new Headers();
  //   myHeaders.append('Authorization', `Bearer ${this.auth_token}`)

  //   var formdata = new FormData();
  //   formdata.append("main", file, file.name);
  //   formdata.append("blog_id", id);

  //   var requestOptions: any = {
  //     method: 'POST',
  //     headers: myHeaders,
  //     body: formdata,
  //     redirect: 'follow'
  //   };

  //   return fetch(`${this.baseUrl}/images/blog`, requestOptions)
  // }
  addBlogImage(id: any, file: any) {
    this.auth_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.auth_token}`,
    });

    var myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${this.auth_token}`);

    var formdata = new FormData();
    file.forEach((element: any) => {

      formdata.append(element.name, element.value, element.value.name);
    });

    formdata.append('blog_id', id);

    var requestOptions: any = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow',
    };

    return fetch(`${this.baseUrl}/images/blog`, requestOptions);
  }

  //client search

  searchClient(name: any) {
    this.auth_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.auth_token}`,
    });
    let params = new HttpParams().set('name', name);

    const options = { params: params, headers: headers };
    return this.http.get(`${this.baseUrl}/client/search`, options);
  }

  deleteImage(id: any) {
    this.auth_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.auth_token}`,
    });
    return this.http.delete(`${this.baseUrl}/images/?id=${id}`, {
      headers: headers,
    });
  }

  filterName(value: any) {
    this.auth_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.auth_token}`,
    });
    return this.http.get(`${this.baseUrl}/orders/clients?name=${value}`, {
      headers: headers,
    });
  }

  filterProd(value: any) {
    this.auth_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.auth_token}`,
    });
    return this.http.get(`${this.baseUrl}/orders/filtered?name=${value}`, {
      headers: headers,
    });
  }
}
