<section>
  <div class="container table-responsive">
    <h1>All Archived Orders</h1>
    <div class="filters">
      <div>
        <label>Search By <strong>Phone No.</strong></label>
        <input
          (input)="phoneNumberSearch($event)"
          type="search"
          class="form-control"
          [(ngModel)]="clientPhoneNumberValue"
        />
      </div>
      <div>
        <label>Search by <strong>Products</strong></label>
        <input
          type="search"
          (input)="productNameSearch($event)"
          class="form-control"
          [(ngModel)]="productNameValue"
        />
      </div>
    </div>
    <!-- <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Name</th>
          <th scope="col">Products</th>
          <th scope="col">Date</th>
          <th scope="col">Packages</th>
          <th scope="col">Status</th>
          <th scope="col">Paid</th>
          <th scope="col">Delivered</th>
          <th scope="col">Total Price</th>
        </tr>
      </thead>
      <tbody *ngIf="!showMessage">
        <tr
          style="cursor: pointer"
          (click)="orderDetails(item.id)"
          *ngFor="let item of orders_global"
        >
          <td>{{ item.id }}</td>
          <td>{{ item?.client?.username }}</td>
          <td>{{item.OrdersProducts.length}}</td>
          <td>{{ item.issue_date | date : "EEEE d MMMM y hh:mm" }}</td>
          <td>{{ item.packages.length }}</td>
          <td *ngIf="item.cashed == true">Cash On Delivery</td>
          <td *ngIf="item.cashed == false">Vodafone Cash</td>
          <td *ngIf="item.paid == null">No</td>
          <td *ngIf="item.paid != null">Yes</td>
          <td *ngIf="item.delivered == null">No</td>
          <td *ngIf="item.delivered != null">Yes</td>
          <td>{{ item.total_price }}</td>
        </tr>
      </tbody>
      <h1 *ngIf="showMessage">NO DATA FOUND</h1>
    </table> -->

    <div class="table">
      <table
        mat-table
        [dataSource]="allArchivedOrders"
        matSort
        matSortDirection="desc"
        matSortDisableClear
      >
        <ng-container matColumnDef="#">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
            <strong>#</strong>
          </th>
          <td mat-cell *matCellDef="let element; let i = index">
            {{ element?.id }}
          </td>
        </ng-container>

        <ng-container matColumnDef="username">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
            <strong>Client name</strong>
          </th>
          <td mat-cell *matCellDef="let row">
            {{ row?.client?.username }}
          </td>
        </ng-container>

        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
            <strong>Date</strong>
          </th>
          <td mat-cell *matCellDef="let row">
            {{ row?.issue_date | date : "EEEE d MMMM y hh:mm" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="packages">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
            <strong>Packages</strong>
          </th>
          <td mat-cell *matCellDef="let row">
            {{ row?.packages.length }}
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
            <strong>Status</strong>
          </th>
          <td mat-cell *matCellDef="let row">
            {{
              row?.cashed
                ? "Cash On Delivery"
                : !row?.cashed
                ? "Vodafone Cash"
                : ""
            }}
          </td>
        </ng-container>

        <ng-container matColumnDef="paid">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
            <strong>Paid</strong>
          </th>
          <td mat-cell *matCellDef="let row">
            {{ row?.paid ? "Yes" : !row?.paid ? "No" : "" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="delivered">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
            <strong>Delivered</strong>
          </th>
          <td mat-cell *matCellDef="let row">
            {{ row?.delivered ? "Yes" : !row?.delivered ? "No" : "" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="totalPrice">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
            <strong>Total Price</strong>
          </th>
          <td mat-cell *matCellDef="let row">
            {{ row?.total_price }} <strong>LE</strong>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          (mouseover)="onRowHovered(row)"
          (mouseleave)="handleMouseLeave()"
          (mousedown)="orderDetails(row.id)"
          [ngClass]="{ highlight: row == selectedRow }"
        ></tr>
      </table>
    </div>

    <div *ngIf="allArchivedOrders?.length === 0">
      <p>Sorry, no data to display!</p>
    </div>
    <mat-paginator
      [pageSize]="10"
      [length]="totalCount"
      [pageIndex]="page - 1"
      (page)="changePage($event)"
    ></mat-paginator>
  </div>
</section>
