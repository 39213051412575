<section>
  <div class="controls mb-3">
    <!-- <button class="addButton" mat-raised-button data-bs-toggle="modal" data-bs-target="#product"> + Add
            Product</button> -->
    <!-- <button mat-raised-button data-bs-toggle="modal" data-bs-target="#category"> Edit Category</button> -->
    <button
      color="warn"
      data-bs-toggle="modal"
      data-bs-target="#DeletePackage"
      class="btn btn-danger"
    >
      Delete Packages
    </button>

    <button
      (click)="getDataForEditModel()"
      data-bs-toggle="modal"
      data-bs-target="#EditPackage"
      class="btn btn-primary ms-2"
    >
      Edit Package
    </button>
  </div>
  <div class="row">
    <div class="col-12 col-md-6">
      <div class="row">
        <div class="col-12 col-md-6">
          <img
            class="img-fluid"
            (error)="packImage = 'assets/images/rk.jpeg'"
            [src]="packImage"
          />
        </div>
        <div class="col-12 col-md-6">
          <h1>Name : {{ packName }}</h1>
          <h2>Price : {{ totalPrice }} <strong>LE</strong></h2>
          <h3>Discount : {{ pack_discount }} <strong>LE</strong></h3>
        </div>
      </div>
    </div>
    <hr />
    <h2>Products</h2>
    <div class="row" *ngIf="showProduct">
      <div class="col-12 col-md-3" *ngFor="let item of product_arr">
        <div class="text-center">
          <img class="img-fluid" [src]="item.products.image[0]" />
          <h2>{{ item.products.name }}</h2>
          <p>Amount : {{ item.amount }}</p>
        </div>
      </div>
    </div>
    <div *ngIf="!showProduct">
      <h2 class="text-center">No Products Found</h2>
    </div>
  </div>
</section>

<!-- <div class="modal fade" id="product" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form [formGroup]="productForm" *ngIf="!showUpload">
                        <div >
                            <label>Product Name</label>
                            <input formControlName="name" type="text" class="form-control">
                        </div>
                        <div class="mt-2">
                            <label>Product Price</label>
                            <input formControlName="price" type="text" class="form-control">
                        </div>
                        <div class="mt-2">
                            <label>Product description</label>
                            <textarea formControlName="description" type="text" class="form-control"></textarea>
                        </div>
                        <div class="mt-2">
                            <label>Product quantity</label>
                            <input formControlName="quantity" type="text" class="form-control">
                        </div>
                    </form>
                    <div *ngIf="showUpload">
                        <label>Product Image</label>
                        <input (change)="uploadFile($event)" type="file" class="form-control">
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button *ngIf="!showUpload" (click)="addProduct()" type="button"
                            class="btn btn-primary">Continue</button>
                        <button *ngIf="showUpload" data-bs-dismiss="modal" (click)="addProductImage()" type="button"
                            class="btn btn-primary">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="category" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div>
                        <label>New Category Name</label>
                        <input [(ngModel)]="cat_name" type="text" class="form-control">
                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>

                        <button (click)="editCat()" type="button" data-bs-dismiss="modal" class="btn btn-primary">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    -->
<div
  class="modal fade"
  id="DeletePackage"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div>
          <h1>Are You Sure Delete this Package ({{ packName }})</h1>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Cancel
          </button>

          <button
            (click)="deletePackage()"
            type="button"
            data-bs-dismiss="modal"
            class="btn btn-primary"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="EditPackage"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header d-flex align-items-between">
        <strong>Edit Package</strong>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>

      <div class="modal-body">
        <div class="form-group">
          <form [formGroup]="packageForm">
            <label>Product Discount <strong>( LE )</strong> </label>
            <input
              type="number"
              class="form-control"
              formControlName="discount"
            />
          </form>
        </div>
      </div>

      <div class="modal-footer">
        <button type="button" data-bs-dismiss="modal" class="btn btn-secondary">
          Close
        </button>
        <button
          (click)="save()"
          type="button"
          data-bs-dismiss="modal"
          class="btn btn-success"
        >
          Save Package
        </button>
      </div>
    </div>
  </div>
</div>
