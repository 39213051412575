import { Component, OnInit } from '@angular/core';
import { CategoriesService } from 'src/app/services/categori.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-packegs',
  templateUrl: './packegs.component.html',
  styleUrls: ['./packegs.component.css'],
})
export class PackegsComponent implements OnInit {
  cat_arr: any;
  ifProd = false;
  prod_arr: any;
  status_prod = false;
  arrayOfProductPackages: any = [];
  prodName: any;
  catName: any;
  Quantity: any;
  prodID: any;
  arrayChoiceProduct: any = [];
  discount: any;
  allPackages: any = [];
  pack_name: any;
  dataFound = false;
  image_file: any;
  packId: any;
  packImageStatus = true;
  constructor(private rest: CategoriesService, private route: Router) {}

  ngOnInit() {
    this.getData();
    this.getPackages();
  }

  getPackages() {
    this.rest.getPackages().subscribe((res: any) => {
      if (res.length != 0) {
        this.allPackages = res;
        this.dataFound = true;
      } else {
        this.dataFound = false;
      }
    });
  }

  getData() {
    this.rest.listCatOnly().subscribe((res: any) => {
      this.cat_arr = res;
      this.rest.hideSpinner();
    });
  }

  getCatId(item: any) {
    this.catName = item.name;
    this.status_prod = false;
    this.rest.cat_full_details(item.id).subscribe((res: any) => {
      this.status_prod = true;
      if (res.products.length != 0) {
        this.ifProd = true;
        this.prod_arr = res.products;
      } else {
        this.ifProd = false;
      }
    });
  }

  choseProd(item: any) {
    this.prodID = item.id;
    this.prodName = item.name;
  }

  add() {
    this.arrayChoiceProduct.push(this.prodName);
    this.arrayOfProductPackages.push({
      id: this.prodID,
      quantity: this.Quantity,
    });
    this.ifProd = false;
    this.status_prod = false;
    this.catName = '';
    this.prodName = '';
    this.Quantity = '';
  }

  save() {
    this.rest.showSpinner();
    this.discount = +this.discount;
    let obj = {
      discount: this.discount,
      name: this.pack_name,
      products: this.arrayOfProductPackages,
    };
    this.rest.addPack(obj).subscribe(
      (res: any) => {
        this.rest.hideSpinner();
        this.packId = res;
        this.packImageStatus = false;
      },
      (err: any) => {
        this.rest.hideSpinner();
      }
    );
  }

  uploadFile(e: any) {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
    }
    this.image_file = e.target.files[0];
  }

  addPackImage() {
    this.rest.showSpinner();
    this.rest
      .upload_pack_image(this.packId, this.image_file)
      .then((response: any) => response.text())
      .then((result: any) => {
        this.getData();
        this.getPackages()
        this.rest.successToast('Package Added Successfully');
        this.rest.hideSpinner();
      })
      .catch((error: any) => {
        this.rest.hideSpinner();
        this.rest.errorToaster('something wrong happened please reload page');
      });
  }

  packDetails(id: any) {
    this.route.navigate(['main/packages_details', id]);
  }
}
