<section>
  <div class="container table-responsive">
    <div class="text-center">
      <img src="assets/images/woman.png" />
      <h1 class="mb-1">{{ clientObj.first_name }} {{ clientObj.last_name }}</h1>
      <p style="color: gray">{{ clientObj.email }}</p>
      <p style="color: gray">@{{ clientObj.username }}</p>
    </div>

    <section class="location main">
      <div class="d-flex">
        <span class="material-symbols-outlined"> call </span>
        <div>
          <h3>Call</h3>
          <p>{{ clientObj.phone_number }}</p>
        </div>
      </div>
      <div class="d-flex">
        <span class="material-symbols-outlined"> location_on </span>
        <div>
          <h3>Location</h3>
          <p>
            {{ clientObj.address }} {{ clientObj.city }}
            {{ clientObj.governorate }}
          </p>
        </div>
      </div>
    </section>
    <hr />
    <h1>Orders</h1>

    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Products</th>
          <th scope="col">Date</th>
          <th scope="col">Products</th>
          <th scope="col">Status</th>
          <th scope="col">Paid</th>
          <th scope="col">Delivered</th>
          <th scope="col">Total Price</th>
        </tr>
      </thead>
      <tbody>
        <tr
          style="cursor: pointer"
          (click)="orderDetails(item.id)"
          *ngFor="let item of order_arr"
        >
          <td>{{ item.id }}</td>
          <td>{{ item.OrdersProducts.length }}</td>
          <td>{{ item.issue_date | date : "EEEE d MMMM y " }}</td>
          <td>{{ item.OrdersProducts.length }}</td>
          <td *ngIf="item.cashed == true">Cash On Delivery</td>
          <td *ngIf="item.cashed == false">Vodafone Cash</td>
          <td *ngIf="item.paid == null">No</td>
          <td *ngIf="item.paid != null">Yes</td>
          <td *ngIf="item.delivered == null">No</td>
          <td *ngIf="item.delivered != null">Yes</td>
          <td>{{ item.total_price }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</section>
