<section>
  <button
    (click)="EmptyForm()"
    class="addButton"
    mat-raised-button
    data-bs-toggle="modal"
    data-bs-target="#addPromo"
  >
    + Add Promocode
  </button>

  <div class="mt-5 table-responsive">
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Promo code name</th>
          <th scope="col">Percentage</th>
          <th scope="col">Limit</th>
          <th scope="col" style="text-align: right">Quick action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of allPromoCode">
          <th>{{ item.id }}</th>
          <td style="cursor: pointer" (click)="Details(item)">
            {{ item.promo_code }}
          </td>
          <td>{{ item.percentage }}</td>
          <td>{{ item.limit }}</td>
          <td class="actionsImage">
            <img
              mat-raised-button
              data-bs-toggle="modal"
              (click)="editPromocode(item)"
              data-bs-target="#editPromo"
              src="assets/images/edit.png"
            />
            <img
              mat-raised-button
              data-bs-toggle="modal"
              (click)="deletePromo(item)"
              data-bs-target="#Delete"
              src="assets/images/delete.png"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</section>

<div
  class="modal fade"
  id="addPromo"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div>
          <form>
            <div class="form-group mb-3">
              <label>Promo code name</label>
              <input
                [(ngModel)]="promo_code"
                name="code"
                type="text"
                class="form-control"
              />
            </div>
            <div class="form-group mb-3">
              <label>percentage</label>
              <input
                [(ngModel)]="percentage"
                name="percentage"
                type="number"
                class="form-control"
              />
            </div>
            <div class="form-group">
              <label>limit</label>
              <input
                [(ngModel)]="limit"
                name="limit"
                type="number"
                class="form-control"
              />
            </div>
          </form>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Cancel
          </button>

          <button
            (click)="addPromo()"
            type="button"
            data-bs-dismiss="modal"
            class="btn btn-primary"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="editPromo"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div>
          <form>
            <div class="form-group mb-2">
              <label>Promo code name</label>
              <input
                [(ngModel)]="promo_code"
                name="code"
                type="text"
                class="form-control"
              />
            </div>
            <div class="form-group mb-2">
              <label>Percentage</label>
              <input
                [(ngModel)]="percentage"
                name="percentage"
                type="number"
                class="form-control"
              />
            </div>
            <div class="form-group">
              <label>Limit</label>
              <input
                [(ngModel)]="limit"
                name="limit"
                type="number"
                class="form-control"
              />
            </div>
          </form>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Cancel
          </button>

          <button
            (click)="confirmEdit()"
            type="button"
            data-bs-dismiss="modal"
            class="btn btn-primary"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="Delete"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div>
          <h1>Are You Sure Delete this promo code ({{ promoName }})</h1>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Cancel
          </button>

          <button
            (click)="delete()"
            type="button"
            data-bs-dismiss="modal"
            class="btn btn-primary"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
