import { Component, OnInit } from '@angular/core';
import { CategoriesService } from 'src/app/services/categori.service';
import { Router } from '@angular/router';
import { ReplaySubject, takeUntil } from 'rxjs';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css'],
})
export class OrdersComponent implements OnInit {
  order_arr: any;
  showMessage = true;
  orders_global: any = [];

  page: number = 1;
  limit: number = 10;
  totalCount: number = 10;
  loading: boolean = false;
  allOrders: any;
  selectedRow: any;

  phoneNumberSearchKey: string | any = '';
  productNameSearchKey: string | any = '';
  paymentStatusValue: boolean | any;
  deliveryStatusValue: boolean | any;

  clientPhoneNumberValue: string = '';
  productNameValue: string = '';

  paymentStatusSelectedValue?: boolean = undefined;
  deliveryStatusSelectedValue?: boolean = undefined;

  displayedColumns: string[] = [
    '#',
    'username',
    'date',
    'packages',
    'status',
    'paid',
    'delivered',
    'totalPrice',
  ];
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(private rest: CategoriesService, private route: Router) {}

  ngOnInit() {
    this.getSessionStorageDate();
    this.rest.showSpinner();
    this.getAllOrders();
  }

  getAllOrders() {
    this.loading = true;
    this.rest
      .getFilteredOrdersService(
        this.page,
        this.limit,
        this.productNameSearchKey,
        this.phoneNumberSearchKey,
        false,
        this.paymentStatusValue,
        this.deliveryStatusValue
      )
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res: any) => {
        this.rest.hideSpinner();
        this.loading = false;
        this.allOrders = res?.results;
        this.totalCount = res.total_count;
      });
  }

  changePage(e: PageEvent) {
    this.page = e.pageIndex + 1;
    this.getAllOrders();
  }

  orderDetails(id: any) {
    this.route.navigate(['main/order_details', id]);

    if (this.productNameSearchKey) {
      sessionStorage.setItem(
        'orderProductSearchKey',
        this.productNameSearchKey
      );
    }

    if (this.phoneNumberSearchKey) {
      sessionStorage.setItem(
        'orderClientPhoneNumber',
        this.phoneNumberSearchKey
      );
    }

    if (this.paymentStatusValue === true || this.paymentStatusValue === false) {
      sessionStorage.setItem('paymentStatus', this.paymentStatusValue);
    }

    if (
      this.deliveryStatusValue === true ||
      this.deliveryStatusValue === false
    ) {
      sessionStorage.setItem('deliveryStatus', this.deliveryStatusValue);
    }

    sessionStorage.setItem('pageNumber', this.page.toString());
  }

  phoneNumberSearch(phoneNumberSearchEvent: any) {
    this.page = 1;
    this.limit = 10;
    this.phoneNumberSearchKey = phoneNumberSearchEvent?.target?.value;
    this.getAllOrders();
  }

  productNameSearch(productNameSearchEvent: any) {
    this.page = 1;
    this.limit = 10;
    this.productNameSearchKey = productNameSearchEvent?.target?.value;
    this.getAllOrders();
  }

  paymentStatusFilter(value: any) {
    if (value == 'all') {
      this.paymentStatusValue = undefined;
      this.getAllOrders();
    } else {
      this.paymentStatusValue = value;
      this.getAllOrders();
    }
  }

  deliveryStatusFilter(value: any) {
    if (value == 'all') {
      this.deliveryStatusValue = undefined;
      this.getAllOrders();
    } else {
      this.deliveryStatusValue = value;
      this.getAllOrders();
    }
  }

  onRowHovered(row: any) {
    this.selectedRow = row;
  }

  handleMouseLeave() {
    this.selectedRow = false;
  }

  getSessionStorageDate() {
    //Product Name
    this.productNameSearchKey = sessionStorage.getItem('orderProductSearchKey');
    sessionStorage.removeItem('orderProductSearchKey');
    this.productNameValue = this.productNameSearchKey;

    //Phone number
    this.phoneNumberSearchKey = sessionStorage.getItem(
      'orderClientPhoneNumber'
    );
    sessionStorage.removeItem('orderClientPhoneNumber');
    this.clientPhoneNumberValue = this.phoneNumberSearchKey;

    //Page number
    const pageNumberStored = sessionStorage.getItem('pageNumber');
    if (pageNumberStored) {
      const myRetrievedPageNumber = parseFloat(pageNumberStored);
      this.page = myRetrievedPageNumber;
    }
    sessionStorage.removeItem('pageNumber');

    //Payment Status
    this.paymentStatusValue = sessionStorage.getItem('paymentStatus');
    sessionStorage.removeItem('paymentStatus');

    if (this.paymentStatusValue === 'true') {
      var trueValue = this.paymentStatusValue === 'true';
      this.paymentStatusSelectedValue = trueValue;
      this.paymentStatusValue = trueValue;
    } else if (this.paymentStatusValue === 'false') {
      var falseValue = this.paymentStatusValue === 'true';
      this.paymentStatusSelectedValue = falseValue;
      this.paymentStatusValue = falseValue;
    }

    //Delivery Status
    this.deliveryStatusValue = sessionStorage.getItem('deliveryStatus');
    sessionStorage.removeItem('deliveryStatus');

    if (this.deliveryStatusValue === 'true') {
      var trueValue = this.deliveryStatusValue === 'true';
      this.deliveryStatusSelectedValue = trueValue;
      this.deliveryStatusValue = trueValue;
    } else if (this.deliveryStatusValue === 'false') {
      var falseValue = this.deliveryStatusValue === 'true';
      this.deliveryStatusSelectedValue = falseValue;
      this.deliveryStatusValue = falseValue;
    }
  }
}
