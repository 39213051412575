import { Component, OnInit } from '@angular/core';
import { CategoriesService } from 'src/app/services/categori.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-promocode',
  templateUrl: './promocode.component.html',
  styleUrls: ['./promocode.component.css'],
})
export class PromocodeComponent implements OnInit {
  promo_code: any;
  percentage: any;
  limit: any;

  allPromoCode: any = [];

  constructor(private rest: CategoriesService, private route: Router) {}

  ngOnInit() {
    this.allPromos();
  }

  EmptyForm() {
    this.promo_code = '';
    this.percentage = '';
    this.limit = '';
  }
  addPromo() {
    this.rest.showSpinner();
    let obj = {
      promo_code: this.promo_code,
      percentage: this.percentage,
      limit: this.limit,
    };
    this.rest.addPromo(obj).subscribe(
      (res: any) => {
        this.rest.successToast('promo code added');
        this.rest.hideSpinner();
        this.allPromos();
      },
      (err: any) => {
        this.rest.hideSpinner();
        this.rest.errorToaster('somthing wrong');
      }
    );
  }

  allPromos() {
    this.rest.allPromos().subscribe((res: any) => {
      this.allPromoCode = res;
    });
  }

  promoId: any;
  editPromocode(item: any) {
    this.promo_code = item.promo_code;
    this.percentage = item.percentage;
    this.limit = item.limit;
    this.promoId = item.id;
  }

  confirmEdit() {
    let obj = {
      promo_code: this.promo_code,
      percentage: this.percentage,
      limit: this.limit,
      id: this.promoId,
    };

    this.rest.editPromo(obj).subscribe((res: any) => {
      this.rest.successToast('Promo code edit successfully');
      this.allPromos();
    });
  }

  promoName: any;
  delete() {
    this.rest.deletePromo(this.promoId).subscribe((res: any) => {
      this.rest.successToast('Promo code Delete successfully');
      this.allPromos();
    });
  }
  deletePromo(item: any) {
    this.promoName = item.promo_code;
    this.promoId = item.id;
  }

  Details(item: any) {
    localStorage.setItem('promo', JSON.stringify(item));
    this.route.navigateByUrl('main/promocode_details');
  }
}
