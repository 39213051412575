import { Component, OnInit } from '@angular/core';
import { CategoriesService } from 'src/app/services/categori.service';
import { Router } from '@angular/router';
import { ReplaySubject, takeUntil } from 'rxjs';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-all-clients',
  templateUrl: './all-clients.component.html',
  styleUrls: ['./all-clients.component.css'],
})
export class AllClientsComponent implements OnInit {
  allClientsArr: any;
  showMessage = false;

  page: number = 1;
  limit: number = 10;
  totalCount: number = 10;
  loading: boolean = false;
  allOrders: any;
  selectedRow: any;

  clientNameSearchKey: string | any = '';
  clientNameValue: string = '';

  displayedColumns: string[] = [
    '#',
    'username',
    'phoneNumber',
    'emailAddress',
    'ordersCount',
  ];

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  constructor(private rest: CategoriesService, private route: Router) {}

  ngOnInit() {
    this.getSessionStorageDate();
    this.rest.showSpinner();
    this.allClients();
  }

  allClients() {
    this.rest
      .getAllClients(this.page, this.limit, this.clientNameSearchKey)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res: any) => {
        this.rest.hideSpinner();
        this.loading = false;
        this.allClientsArr = res?.results;
        this.totalCount = res.total_count;
      });
  }

  changePage(e: PageEvent) {
    this.page = e.pageIndex + 1;
    this.allClients();
  }

  clientDetails(id: any) {
    this.route.navigate(['main/client_details', id]);

    if (this.clientNameSearchKey) {
      sessionStorage.setItem('clientNameSearchKey', this.clientNameSearchKey);
    }

    sessionStorage.setItem('pageNumber', this.page.toString());
  }

  clientNameSearch(clientNameSearchEvent: any) {
    this.page = 1;
    this.limit = 10;
    this.clientNameSearchKey = clientNameSearchEvent?.target?.value;
    this.allClients();
  }

  onRowHovered(row: any) {
    this.selectedRow = row;
  }

  handleMouseLeave() {
    this.selectedRow = false;
  }

  getSessionStorageDate() {
    //Client Name
    this.clientNameSearchKey = sessionStorage.getItem('clientNameSearchKey');
    sessionStorage.removeItem('clientNameSearchKey');
    this.clientNameValue = this.clientNameSearchKey;

    //Page number
    const pageNumberStored = sessionStorage.getItem('pageNumber');
    if (pageNumberStored) {
      const myRetrievedPageNumber = parseFloat(pageNumberStored);
      this.page = myRetrievedPageNumber;
    }
    sessionStorage.removeItem('pageNumber');
  }
}
