<section class="main">
  <div>
    <button
      class="add_button"
      mat-raised-button
      data-bs-toggle="modal"
      data-bs-target="#exampleModal"
    >
      + Add New Blog
    </button>
  </div>

  <table class="mt-5 table table-striped">
    <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Name</th>
        <th scope="col">Date</th>
      </tr>
    </thead>
    <tbody *ngIf="!showMessage">
      <tr
        style="cursor: pointer"
        (click)="blogDetails(item.id)"
        *ngFor="let item of blogsArr"
      >
        <td>{{ item.id }}</td>
        <td>{{ item.title }}</td>
        <td>{{ item.joined_date | date : "EEEE d MMMM y " }}</td>
      </tr>
    </tbody>
    <h1 *ngIf="showMessage">NO DATA FOUND</h1>
  </table>
</section>

<div
  class="modal fade"
  id="exampleModal"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="mt-2" *ngIf="!showUploadFile">
          <label>Blog Name</label>
          <input [(ngModel)]="title" type="text" class="form-control" />
        </div>
        <div class="mt-2" *ngIf="!showUploadFile">
          <label>Content</label>
          <input [(ngModel)]="content" type="text" class="form-control" />
        </div>
        <div class="mt-2" *ngIf="!showUploadFile">
          <label>Steps</label>
          <input [(ngModel)]="steps" type="text" class="form-control" />
        </div>
        <div class="mt-2" *ngIf="!showUploadFile">
          <div class="dropdown">
            <button
              class="btn btn-secondary dropdown-toggle"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Choose Product
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li *ngFor="let item of allProductsArray">
                <a
                  class="dropdown-item"
                  *ngIf="item.quantity > 0"
                  (click)="choseProd(item)"
                >
                  {{ item.name }}</a
                >
              </li>
            </ul>
            <ul>
              <li *ngFor="let item of idOfProducts">
                {{ item.name }}
              </li>
            </ul>
          </div>
        </div>
        <div *ngIf="showUploadFile">
          <form>
            <label>Blog Image</label>
            <input
              multiple
              (change)="uploadFile($event, 'main')"
              type="file"
              class="form-control"
            />

            <label>Blog Video</label>
            <input
              multiple
              (change)="uploadFile($event, 'video')"
              type="file"
              class="form-control"
            />
          </form>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Close
        </button>
        <button
          *ngIf="!showUploadFile"
          (click)="addBlog()"
          type="button"
          class="btn btn-primary"
        >
          Continue
        </button>
        <button
          *ngIf="showUploadFile"
          (click)="addBlogImage()"
          data-bs-dismiss="modal"
          type="button"
          class="btn btn-primary"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</div>
