import { Component, OnInit } from '@angular/core';
import { CategoriesService } from 'src/app/services/categori.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.css'],
})
export class BlogsComponent implements OnInit {
  blogsArr: any;
  showMessage = false;
  title: any;
  steps: any;
  content: any;
  showUploadFile = false;
  allProductsArray: any;
  idOfProducts: any = [];
  productsIds: any = [];
  image_file: any;
  blogId: any;
  constructor(private rest: CategoriesService, private route: Router) {}

  ngOnInit() {
    this.getData();
    this.allProduct();
  }

  getData() {
    this.rest.getBlogs().subscribe((res: any) => {
      if (res.length == 0) {
        this.showMessage = true;
      } else {
        this.showMessage = false;
        this.blogsArr = res;
      }
    });
  }
  allProduct() {
    this.rest.allProduct().subscribe((res: any) => {
      this.allProductsArray = res;
    });
  }
  DetailsBlog(id: any) {}

  addBlog() {
    let obj = {
      title: this.title,
      steps: this.steps,
      content: this.content,
      products: this.productsIds,
    };
    this.rest.addBlog(obj).subscribe(
      (res: any) => {
        this.blogId = res;
        this.rest.successToast('Blog added Successfully');
        this.rest.hideSpinner();
        this.showUploadFile = true;
        // this.getData()
      },
      (err: any) => {
        this.rest.errorToaster('Something wrong try again');
        this.rest.hideSpinner();
      }
    );
  }

  imagesArr: any = [];

  uploadFile(e: any, name: any) {
    let dummy: any = e.target.files;
    for (let i = 0; i < dummy.length; i++) {
      this.imagesArr.push({
        name: name + [i],
        value: dummy[i],
      });
    }
  }

  addBlogImage() {
    this.rest.showSpinner();
    this.rest
      .addBlogImage(this.blogId, this.imagesArr)
      .then((response: any) => response.text())
      .then((result: any) => {
        this.getData();
        this.rest.successToast('image Added Successfully');
        this.rest.hideSpinner();
      })
      .catch((error: any) => {
        this.rest.hideSpinner();
        this.rest.errorToaster('something wrong happened please reload page');
      });
  }

  choseProd(item: any) {
    if (!this.idOfProducts.includes(item)) {
      this.idOfProducts.push(item);
      this.productsIds.push(item.id);
    } else {
    }
  }

  blogDetails(id: any) {
    this.route.navigate(['main/blog_Details', id]);
  }
}
