<div class="container">
  <div class="modal-content">
    <div class="modal-body">
      <div class="mb-3">
        <form [formGroup]="registerForm">
          <h1>Admin register</h1>
          <div class="form-group mb-3">
            <label>Email</label>
            <input
              formControlName="email"
              name="Email"
              type="email"
              class="form-control"
            />

            <span
              class="text-danger"
              *ngIf="registerForm.controls?.email?.errors?.required"
            >
              Email address is Required
            </span>

            <span
              class="text-danger"
              *ngIf="registerForm.controls?.email?.errors?.pattern"
            >
              Email address is invalid
            </span>
          </div>

          <div class="form-group mb-3">
            <label>Username</label>
            <input
              formControlName="username"
              name="Username"
              type="text"
              class="form-control"
            />

            <span
              class="text-danger"
              *ngIf="registerForm.controls?.username?.errors?.required"
            >
              Username is Required
            </span>
          </div>

          <div class="form-group mb-3">
            <label>Name</label>
            <input
              formControlName="name"
              name="Name"
              type="text"
              class="form-control"
            />

            <span
              class="text-danger"
              *ngIf="registerForm.controls?.name?.errors?.required"
            >
              Name is Required
            </span>
          </div>

          <div class="form-group mb-3">
            <label>Phone number</label>
            <input
              formControlName="phone_number"
              name="phone number"
              type="text"
              class="form-control"
            />

            <span
              class="text-danger"
              *ngIf="registerForm.controls?.phone_number?.errors?.required"
            >
              Phone number is Required
            </span>

            <span
              class="text-danger"
              *ngIf="
                registerForm.controls?.phone_number?.errors?.minlength ||
                registerForm.controls?.phone_number?.errors?.maxlength
              "
            >
              Phone number is invalid
            </span>
          </div>

          <div class="form-group mb-3">
            <label>Password</label>
            <input
              formControlName="password"
              name="password"
              type="password"
              class="form-control mb-2"
            />

            <span
              class="text-danger"
              *ngIf="registerForm.controls?.password?.errors?.required"
            >
              Password is Required
            </span>

            <span
              class="text-danger"
              *ngIf="registerForm.controls?.password?.errors?.minlength"
            >
              Password should be at least 8 characters
            </span>

            <span
              class="text-danger"
              *ngIf="registerForm.controls?.password?.errors?.maxlength"
            >
              Password should be at most 128 characters
            </span>
          </div>
        </form>
      </div>

      <div class="modal-footer">
        <button
          [disabled]="registerForm.invalid"
          (click)="register()"
          type="button"
          data-bs-dismiss="modal"
          class="btn btn-primary"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</div>
