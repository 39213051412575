import { Component, OnInit } from '@angular/core';
import { CategoriesService } from 'src/app/services/categori.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-order-request',
  templateUrl: './order-request.component.html',
  styleUrls: ['./order-request.component.css'],
})
export class OrderRequestComponent implements OnInit {
  orders_arr: any;
  showMessage = false;

  ngOnInit() {
    this.getData();
  }

  constructor(
    private rest: CategoriesService,
    private _sanitizer: DomSanitizer
  ) {}

  getData() {
    this.rest.orderRequest().subscribe((res: any) => {
      res.forEach((element: any) => {
        if (element.image.length != 0) {
          element.image[0] = this._sanitizer.bypassSecurityTrustUrl(
            `data:image/png;base64,` + element.image[0]
          );
        } else {
          element.image[0] = 'assets/images/rk.jpeg';
        }
      });
      this.orders_arr = res;
    });
  }
}
