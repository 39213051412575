import { Component, OnInit } from '@angular/core';
import { CategoriesService } from 'src/app/services/categori.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-prod-details',
  templateUrl: './prod-details.component.html',
  styleUrls: ['./prod-details.component.css'],
})
export class ProdDetailsComponent implements OnInit {
  productId: any;
  prodcutImageArr: any;
  prodObj: any = {
    description: '',
    name: '',
    price: '',
    quantity: '',
    images: [
      {
        image_url: '',
      },
    ],
  };
  product_obj: any;
  productForm: FormGroup | any;
  description: any;
  constructor(
    private rest: CategoriesService,
    private _sanitizer: DomSanitizer,
    private _location: Location,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private routing: Router
  ) {}

  ngOnInit() {
    this.productId = this.route.snapshot.paramMap.get('id');
    this.getData();
    this.productForm = this.formBuilder.group({
      name: ['', Validators.required],
      price: ['', Validators.required],
      description: ['', Validators.required],
      quantity: ['', Validators.required],
    });
  }

  screenshotArray: any = [];
  videosArr: any = [];
  mainImageArr: any = [];
  imageBath: any = '';
  getData() {
    this.screenshotArray = [];
    this.videosArr = [];
    this.mainImageArr = [];
    this.rest.getProduct(this.productId).subscribe((res: any) => {
      this.description = res.description;

      if (this.description.includes('\u2022')) {
        this.description = this.description.split('\u2022');
      } else {
        this.description = this.description.split('.');
      }

      this.product_obj = res;

      this.prodObj = res;

      if (this.prodObj.images.length != 0) {
        for (let i = 0; i < this.prodObj.images.length; i++) {
          this.prodObj.images[
            i
          ].image_url = `${environment.apiUrl}/images/?id=${this.prodObj.images[i].image_url}`;

          if (this.prodObj.images[i].image_type != null) {
            if (this.prodObj.images[i].image_type.includes('screenshot')) {
              this.screenshotArray.push(this.prodObj.images[i]);
            }
            if (this.prodObj.images[i].image_type.includes('video')) {
              this.videosArr.push(this.prodObj.images[i]);
            }
            if (this.prodObj.images[i].image_type.includes('main')) {
              this.mainImageArr.push(this.prodObj.images[i]);
            }
          }
        }
        if (this.mainImageArr.length != 0) {
          this.imageBath = this.mainImageArr[0].image_url;
        } else {
          this.imageBath = this.prodObj.images[0].image_url;
        }
      } else {
        this.prodObj.images = [
          {
            image_url: 'assets/images/rk.jpeg',
          },
        ];
      }

    });
  }

  editProduct() {
    let obj = this.productForm.value;
    obj.id = this.productId;
    this.rest.editProduct(obj).subscribe(
      (res: any) => {
        // this.rest.successToast("Product Info Edit Successfully")
        // this.rest.hideSpinner()
        this.addProductImage();
      },
      (err: any) => {
        this.rest.hideSpinner();
        this.rest.errorToaster(
          'something wrong happened When Edit Info please Try Again'
        );
      }
    );
  }

  addData() {
    this.productForm.patchValue(this.product_obj);
  }

  deleteProduct() {
    this.rest.deleteProduct(this.productId).subscribe((res: any) => {
      this.rest.successToast('Product Delete Successfully');
      this._location.back();
      // this.routing.navigateByUrl("main/categoris")
    });
  }

  image_file: any = [];
  uploadFile(e: any, type: any) {
    // if (e.target.files.length > 0) {
    //   const file = e.target.files[0];
    // }
    // this.image_file = e.target.files[0]

    let dummy: any = e.target.files;
    for (let i = 0; i < dummy.length; i++) {
      this.image_file.push({
        name: type + [i],
        value: dummy[i],
      });
    }

  }

  addProductImage() {
    this.rest.showSpinner();
    if (this.image_file) {
      this.rest
        .upload_product_image(this.productId, this.image_file)
        .then((response: any) => response.text())
        .then((result: any) => {
          this.screenshotArray = [];
          this.videosArr = [];
          this.mainImageArr = [];
          this.image_file = [];
          this.rest.hideSpinner();
          this.getData();
          this.rest.successToast('Product image updated Successfully');
          this.rest.hideSpinner();
        })
        .catch((error: any) => {
          this.rest.errorToaster(
            'something wrong happened When Edit Image please Try Again'
          );
          this.rest.hideSpinner();
        });
    } else {
      this.getData();
      this.rest.hideSpinner();
    }
  }

  DeleteImage(id: any) {
    this.rest.deleteImage(id).subscribe((res: any) => {
      this.getData();
      this.rest.successToast('Deleted Success');
    });
  }
}
