<section>
  <div class="controls mb-3">
    <button
      class="addButton"
      mat-raised-button
      data-bs-toggle="modal"
      data-bs-target="#product"
    >
      + Add Product
    </button>
    <button mat-raised-button data-bs-toggle="modal" data-bs-target="#category">
      Edit Category
    </button>
    <button
      mat-raised-button
      color="warn"
      data-bs-toggle="modal"
      data-bs-target="#DeleteCategory"
    >
      Delete Category
    </button>
  </div>
  <div class="mt-5">
    <h1 class="headLine">- {{ cat_obj.name | uppercase }}</h1>
  </div>

  <div class="filter">
    <!-- <span class="material-symbols-outlined">
            tune
        </span> -->
    <input
      (input)="search($event)"
      type="search"
      placeholder="Find Product ...."
      class="form-control"
    />
  </div>
  <div class="row">
    <hr />
    <h2>Products</h2>
    <div class="row" *ngIf="showProduct">
      <div class="col-6 col-md-3 col-xl-3" *ngFor="let item of product_arr">
        <div (click)="productDetails(item.id)" class="text-center prodCard">
          <img class="img-fluid" [src]="item.images[0].image_url" />
          <h2>{{ item.name }}</h2>
        </div>
      </div>
    </div>
    <div *ngIf="!showProduct">
      <h2 class="text-center">No Products Found</h2>
    </div>
  </div>
</section>

<div
  class="modal fade"
  id="product"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <form [formGroup]="productForm" *ngIf="!showUpload">
          <div>
            <label>Product Name *</label>
            <input formControlName="name" type="text" class="form-control" />
            <span
              class="text-danger"
              *ngIf="
                (productForm.controls.name.touched || formStatus) &&
                productForm.controls.name.errors?.required
              "
            >
              Product Name is Required
            </span>
          </div>
          <div class="mt-2">
            <label>Product Price *</label>
            <input formControlName="price" type="text" class="form-control" />
            <span
              class="text-danger"
              *ngIf="
                (productForm.controls.price.touched || formStatus) &&
                productForm.controls.price.errors?.required
              "
            >
              Product Price is Required
            </span>
          </div>
          <div class="mt-2">
            <label>Product description *</label>
            <textarea
              formControlName="description"
              type="text"
              class="form-control"
            ></textarea>
            <span
              class="text-danger"
              *ngIf="
                (productForm.controls.description.touched || formStatus) &&
                productForm.controls.description.errors?.required
              "
            >
              Product description is Required
            </span>
          </div>
          <div class="mt-2">
            <label>Product quantity *</label>
            <input
              formControlName="quantity"
              type="text"
              class="form-control"
            />
            <span
              class="text-danger"
              *ngIf="
                (productForm.controls.quantity.touched || formStatus) &&
                productForm.controls.quantity.errors?.required
              "
            >
              Product quantity is Required
            </span>
          </div>
        </form>
        <div *ngIf="showUpload">
          <label>Product Image</label>
          <input
            multiple
            (change)="uploadFile($event, 'main')"
            type="file"
            class="form-control"
          />
        </div>
        <div *ngIf="showUpload">
          <label>Product video</label>
          <input
            multiple
            (change)="uploadFile($event, 'video')"
            type="file"
            class="form-control"
          />
        </div>
        <div *ngIf="showUpload">
          <label>Product screenshot</label>
          <input
            multiple
            (change)="uploadFile($event, 'screenshot')"
            type="file"
            class="form-control"
          />
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <button
            *ngIf="!showUpload"
            (click)="addProduct()"
            type="button"
            class="btn btn-primary"
          >
            Continue
          </button>
          <button
            *ngIf="showUpload"
            data-bs-dismiss="modal"
            (click)="addProductImage()"
            type="button"
            class="btn btn-primary"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="category"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div>
          <label>New Category Name</label>
          <input [(ngModel)]="cat_name" type="text" class="form-control" />
        </div>
        <div class="mt-2">
          <label>New Category Image</label>
          <input
            (change)="uploadFileCat($event)"
            type="file"
            class="form-control"
          />
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>

          <button
            (click)="editCat()"
            type="button"
            data-bs-dismiss="modal"
            class="btn btn-primary"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="modal fade"
  id="DeleteCategory"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div>
          <h1>Are You Sure Delete this Category ({{ cat_obj.name }})</h1>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Cancel
          </button>

          <button
            (click)="deleteCategory()"
            type="button"
            data-bs-dismiss="modal"
            class="btn btn-primary"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
