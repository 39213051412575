import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoriesService } from 'src/app/services/categori.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.css'],
})
export class BlogDetailsComponent implements OnInit {
  showMessage = false;
  blogId: any;
  blogName: any = '';
  blogContent: any = '';
  blogSteps: any = '';
  blogProduct: any = [];
  blogData: any = '';
  videosArr: any = [];
  mainArrImages: any = [];
  constructor(
    private route: ActivatedRoute,
    private routing: Router,
    private rest: CategoriesService
  ) {}

  ngOnInit() {
    this.blogId = this.route.snapshot.paramMap.get('id');
    this.getData();
  }

  getData() {
    this.mainArrImages = [];
    this.videosArr = [];
    this.rest.getBlogDetails(this.blogId).subscribe((res: any) => {
      this.blogName = res.title;
      this.blogContent = res.content;
      this.blogSteps = res.steps;
      this.blogProduct = res.products;
      this.blogData = res.joined_date;
      this.title = res.title;
      this.content = res.content;
      this.steps = res.steps;
      if (this.blogProduct.length == 0) {
        this.showMessage = true;
      } else {
        this.blogProduct.forEach((element: any) => {
          element.image[0].image_url = `${environment.apiUrl}/images/?id=${element.image[0].image_url}`;
        });
        this.showMessage = false;
      }

      if (res.media.length != 0) {
        for (let i = 0; i < res.media.length; i++) {
          res.media[
            i
          ].image_url = `${environment.apiUrl}/images/?id=${res.media[i].image_url}`;
          if (res.media[i].image_type.includes('video')) {
            this.videosArr.push(res.media[i]);
          }
          if (res.media[i].image_type.includes('main')) {
            this.mainArrImages.push(res.media[i]);
          }
        }
      }
    });
  }

  DeleteBlog() {
    this.rest.deleteBlog(this.blogId).subscribe((res: any) => {
      this.routing.navigateByUrl('main/blogs');
      this.rest.successToast('blog Deleted');
    });
  }

  title: any;
  content: any;
  steps: any;
  editBlog() {
    let obj = {
      id: this.blogId,
      title: this.title,
      steps: this.steps,
      content: this.content,
    };
    this.rest.editBlog(obj).subscribe((res: any) => {
      this.rest.successToast('Blog Updated');
      this.getData();
    });
    this.addBlogImage();
  }
  imagesArr: any = [];
  uploadFile(e: any, name: any) {
    let dummy: any = e.target.files;
    for (let i = 0; i < dummy.length; i++) {
      this.imagesArr.push({
        name: name + [i],
        value: dummy[i],
      });
    }
  }
  addBlogImage() {
    if (this.imagesArr.length != 0) {
      this.rest.showSpinner();
      this.rest
        .addBlogImage(this.blogId, this.imagesArr)
        .then((response: any) => response.text())
        .then((result: any) => {
          this.getData();
          this.rest.successToast('image Added Successfully');
          this.rest.hideSpinner();
        })
        .catch((error: any) => {
          this.rest.hideSpinner();
          this.rest.errorToaster('something wrong happened please reload page');
        });
    }
  }

  DeleteImage(id: any) {
    this.rest.deleteImage(id).subscribe((res: any) => {
      this.getData();
      this.rest.successToast('Deleted Success');
    });
  }
}
