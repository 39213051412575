<section class="main">
  <!-- <div class="container">
    <div class="mb-4 d-flex align-items-center">
      <span class="material-symbols-outlined icon"> search </span>
      <input
        (input)="search($event)"
        type="search"
        class="form-control"
        placeholder="Find Client"
      />
    </div>
    <div class="row">
      <div class="col-12 mb-3" *ngFor="let item of allClientsArr">
        <div class="cardStyle" (click)="clientDetails(item.id)">
          <img class="avatar" src="assets/images/avatar.jpeg" />
          <div>
            <p>{{ item?.email }}</p>
            <p>{{ item?.phone_number }}</p>
            <p>Orders({{ item.orders?.length }})</p>
          </div>
        </div>
      </div>
    </div>
    <h1 *ngIf="showMessage">NO DATA FOUND</h1>
  </div> -->

  <div class="container table-responsive">
    <h1>All Clients</h1>
    <div class="filters">
      <div>
        <label>Search for <strong>User</strong></label>
        <input
          type="search"
          (input)="clientNameSearch($event)"
          class="form-control"
          [(ngModel)]="clientNameValue"
        />
      </div>
    </div>

    <div class="table">
      <table
        mat-table
        [dataSource]="allClientsArr"
        matSort
        matSortDirection="desc"
        matSortDisableClear
      >
        <ng-container matColumnDef="#">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
            <strong>UID</strong>
          </th>
          <td mat-cell *matCellDef="let element; let i = index">
            {{ element?.id }}
          </td>
        </ng-container>

        <ng-container matColumnDef="username">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
            <strong>Client name</strong>
          </th>
          <td mat-cell *matCellDef="let row">
            {{
              row?.first_name
                ? row?.first_name + " " + row?.last_name
                : (row?.username | titlecase)
            }}
          </td>
        </ng-container>

        <ng-container matColumnDef="phoneNumber">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
            <strong>Phone number</strong>
          </th>
          <td mat-cell *matCellDef="let row">
            {{ row?.phone_number }}
          </td>
        </ng-container>

        <ng-container matColumnDef="emailAddress">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
            <strong>Email</strong>
          </th>
          <td mat-cell *matCellDef="let row">
            {{ row?.email }}
          </td>
        </ng-container>

        <ng-container matColumnDef="ordersCount">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
            <strong>No. of orders</strong>
          </th>
          <td mat-cell *matCellDef="let row">
            {{ row?.orders?.length }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          (mouseover)="onRowHovered(row)"
          (mouseleave)="handleMouseLeave()"
          (mousedown)="clientDetails(row.id)"
          [ngClass]="{ highlight: row == selectedRow }"
        ></tr>
      </table>
    </div>

    <div *ngIf="allClientsArr?.length === 0">
      <p>Sorry, no data to display!</p>
    </div>
    <mat-paginator
      [pageSize]="10"
      [length]="totalCount"
      [pageIndex]="page - 1"
      (page)="changePage($event)"
    ></mat-paginator>
  </div>
</section>
