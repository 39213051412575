import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CategoriesService } from 'src/app/services/categori.service';

@Component({
  selector: 'app-admin-register',
  templateUrl: './admin-register.component.html',
  styleUrls: ['./admin-register.component.css'],
})
export class AdminRegisterComponent implements OnInit {
  registerForm: FormGroup | any;

  constructor(private rest: CategoriesService) {}

  ngOnInit() {
    this.getRegisterForm();
  }

  getRegisterForm() {
    this.registerForm = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.maxLength(128),
        Validators.minLength(8),
      ]),
      username: new FormControl('', [Validators.required]),
      name: new FormControl('', [Validators.required]),
      phone_number: new FormControl('', [
        Validators.required,
        Validators.minLength(11),
        Validators.maxLength(11),
      ]),
      is_admin: new FormControl(true),
    });
  }

  register() {
    this.rest.showSpinner();
    let form = this.registerForm.value;
    if (this.registerForm.valid) {
      this.rest.adminRegister(form).subscribe(
        (res: any) => {
          this.registerForm.reset();
          this.rest.hideSpinner();
          this.rest.successToast('New admin created successfully');
        },
        (err: any) => {
          this.rest.hideSpinner();
        }
      );
    }
  }
}
