<section class="inPC">
  <div class="main">
    <h2 class="text-center">Radwa Khallaf</h2>
    <hr />
    <div class="menu" routerLink="home" routerLinkActive="active">
      <span class="material-symbols-outlined"> home </span>
      <p>Home</p>
    </div>
    <div class="menu" routerLink="categoris" routerLinkActive="active">
      <span class="material-symbols-outlined"> category </span>
      <p>Categories</p>
    </div>
    <div class="menu" routerLink="orders" routerLinkActive="active">
      <span class="material-symbols-outlined"> draft_orders </span>
      <p>Orders</p>
    </div>

    <div class="menu" routerLink="cancelled-orders" routerLinkActive="active">
      <span class="material-symbols-outlined"> draft_orders </span>
      <p>Canceled Orders</p>
    </div>

    <div class="menu" routerLink="archived-orders" routerLinkActive="active">
      <span class="material-symbols-outlined"> draft_orders </span>
      <p>Archived Orders</p>
    </div>

    <div class="menu" routerLink="all_clients" routerLinkActive="active">
      <span class="material-symbols-outlined"> group </span>
      <p>Clients</p>
    </div>
    <div class="menu" routerLink="packages" routerLinkActive="active">
      <span class="material-symbols-outlined"> package </span>
      <p>Packages</p>
    </div>

    <div class="menu" routerLink="blogs" routerLinkActive="active">
      <span class="material-symbols-outlined"> supervisor_account </span>
      <p>Blogs</p>
    </div>

    <div class="menu" routerLink="order_request" routerLinkActive="active">
      <span class="material-symbols-outlined"> list_alt </span>
      <p>Order Request</p>
    </div>

    <div class="menu" routerLink="promocode" routerLinkActive="active">
      <span class="material-symbols-outlined"> edit_document </span>
      <p>Promo Code</p>
    </div>

    <div class="menu" routerLink="admin-register" routerLinkActive="active">
      <span class="material-symbols-outlined"> shield_person </span>
      <p>Admin Register</p>
    </div>

    <!-- <h3>Account Pages</h3>

        <div class="menu">
            <span class="material-symbols-outlined">
                person
            </span>
            <p>Profile</p>
        </div>
        <div class="menu">
            <span class="material-symbols-outlined">
                person_add
            </span>
            <p>Register Admin</p>
        </div> -->
  </div>
</section>

<section class="inMob">
  <div class="main">
    <!-- <h2 class="text-center">App Name</h2> -->
    <img style="width: 35px" src="../../../assets/logo.jpeg" />
    <hr />
    <div class="menu" routerLink="home" routerLinkActive="active">
      <span class="material-symbols-outlined"> home </span>
    </div>
    <div class="menu" routerLink="categoris" routerLinkActive="active">
      <span class="material-symbols-outlined"> category </span>
    </div>
    <div class="menu" routerLink="orders" routerLinkActive="active">
      <span class="material-symbols-outlined"> draft_orders </span>
    </div>

    <div class="menu" routerLink="cancelled-orders" routerLinkActive="active">
      <span class="material-symbols-outlined"> draft_orders </span>
    </div>

    <div class="menu" routerLink="archived-orders" routerLinkActive="active">
      <span class="material-symbols-outlined"> draft_orders </span>
    </div>

    <div class="menu" routerLink="all_clients" routerLinkActive="active">
      <span class="material-symbols-outlined"> group </span>
    </div>
    <div class="menu" routerLink="packages" routerLinkActive="active">
      <span class="material-symbols-outlined"> package </span>
    </div>

    <div class="menu" routerLink="blogs" routerLinkActive="active">
      <span class="material-symbols-outlined">
        <span class="material-symbols-outlined"> supervisor_account </span>
      </span>
    </div>
    <div class="menu" routerLink="order_request" routerLinkActive="active">
      <span class="material-symbols-outlined"> list_alt </span>
    </div>

    <div class="menu" routerLink="promocode" routerLinkActive="active">
      <span class="material-symbols-outlined"> edit_document </span>
    </div>

    <div class="menu" routerLink="admin-register" routerLinkActive="active">
      <span class="material-symbols-outlined"> shield_person </span>
    </div>

    <!-- <span class="material-symbols-outlined">

</span> -->
  </div>
</section>
