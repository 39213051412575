<section class="main">
    <form [formGroup]="loginForm">
        <h1 class="text-center">Welcome, Radwa </h1>
        <!-- Email input -->
        <div class="form-outline mb-4">
            <label class="form-label" for="form2Example1">Email address</label>
            <input formControlName="email" type="email" id="form2Example1" class="form-control" />
        </div>

        <!-- Password input -->
        <div class="form-outline mb-4">
            <label class="form-label" for="form2Example2">Password</label>
            <input formControlName="password" type="password" id="form2Example2" class="form-control" />
        </div>

        <!-- 2 column grid layout for inline styling -->
        <!-- <div class="row mb-4">

                <div class="col">
                    <a href="#!">Forgot password?</a>
                </div>
            </div> -->

        <!-- Submit button -->
        <button (click)="login()" type="button" class="btn btn-primary btn-block mb-4">Sign in</button>

        <!-- Register buttons -->
        <!-- <div class="text-center">
                <p>Not a member? <a href="#!">Register</a></p>
            </div> -->
    </form>
</section>

