import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoriesService } from 'src/app/services/categori.service';

@Component({
  selector: 'app-orders-details',
  templateUrl: './orders-details.component.html',
  styleUrls: ['./orders-details.component.css'],
})
export class OrdersDetailsComponent implements OnInit {
  order_id: any;
  product_arr: any;
  clientName: any;
  packagesArr: any = [];
  order_arr: any = [];
  cancelButtonStatus = false;
  orderDetails: any;

  packImageStatus = true;
  catName: any;
  packageName: any;
  status_prod = false;
  ifProd = false;
  ifPackage = false;
  prod_arr: any;
  cat_arr: any;
  package_arr: any;
  prodID: any;
  packageID: any;
  prodName: any;
  arrayChoiceProduct: any = [];
  arrayChoicePackage: any = [];
  arrayOfProductPackages: any = [];
  arrayOfPackages: any = [];
  Quantity: any;
  packageQuantity: any;

  orderProductsForEditing: any;
  orderPackagesForEditing: any;
  orderAddressForEditing: any;
  orderFees: any;
  orderPhoneNumber: any;

  //Updating Address
  currentProductForm: FormGroup | any;
  currentProductAmountControls: FormControl[] = [];
  currentPackageAmountControls: FormControl[] = [];

  clientAddress: FormGroup | any;
  showAddingNewAddressForm: boolean = false;
  showChangingAddress: boolean = false;
  clientIdForAddress: any;
  clientAddressForUpdatingAddress: any;
  clientAddressIdSelected: any;
  filteredProductDataWithIdAndAmount: any;
  filteredPackagesDataWithIdAndAmount: any;

  constructor(
    private route: ActivatedRoute,
    private routing: Router,
    private rest: CategoriesService
  ) {}

  ngOnInit() {
    this.order_id = this.route.snapshot.paramMap.get('id');
    this.getData();
    this.phoneNumberForm();
  }

  getData() {
    this.rest.orderDetails(this.order_id).subscribe((res: any) => {
      this.orderDetails = res;
      this.orderPhoneNumber = this.orderDetails?.phone_number;

      this.phoneNoForm.patchValue({
        phoneNumber: this.orderPhoneNumber,
      });

      this.orderProductsForEditing = res?.OrdersProducts;
      this.orderPackagesForEditing = res?.packages;
      this.orderAddressForEditing = res?.address;
      this.clientAddressIdSelected = this.orderAddressForEditing?.id;
      this.orderFees = this.orderDetails?.fees;
      this.clientIdForAddress = res?.client?.id;

      //Amount For products
      this.filteredProductDataWithIdAndAmount =
        this.orderProductsForEditing.map((item: any) => {
          return {
            id: item?.ordersproducts?.id,
            quantity: item?.ammount,
          };
        });

      this.orderProductsForEditing.forEach((item: any, index: number) => {
        const control = new FormControl(item.ammount);
        this.currentProductForm.addControl(
          'currentProductAmount' + index,
          control
        );
        this.currentProductAmountControls.push(control);
      });
      //Amount For products

      //Amount For packages
      this.filteredPackagesDataWithIdAndAmount =
        this.orderPackagesForEditing.map((item: any) => {
          return {
            id: item?.orderspackages?.id,
            amount: item?.ammount,
          };
        });

      this.orderPackagesForEditing.forEach((item: any, index: number) => {
        const control = new FormControl(item.ammount);
        this.currentProductForm.addControl(
          'currentPackageAmount' + index,
          control
        );
        this.currentPackageAmountControls.push(control);
      });
      //Amount For packages

      this.order_arr.push(res);
      this.clientName = res?.client?.email;
      this.packagesArr = res?.packages;
      this.product_arr = res?.OrdersProducts;
      if (res.paid == true) {
        this.cancelButtonStatus = true;
      }
    });

    this.getCurrentOrderForm();
  }

  changeProductAmount(productId: any, productAmount: number) {
    for (let i = 0; i < this.filteredProductDataWithIdAndAmount?.length; i++) {
      if (this.filteredProductDataWithIdAndAmount[i].id === productId) {
        if (productAmount === 0) {
          this.filteredProductDataWithIdAndAmount.splice(i, 1);
        } else {
          this.filteredProductDataWithIdAndAmount[i].quantity = productAmount;
        }
        break;
      }
    }
  }

  changePackageAmount(packageId: any, packageAmount: number) {
    for (let i = 0; i < this.filteredPackagesDataWithIdAndAmount?.length; i++) {
      if (this.filteredPackagesDataWithIdAndAmount[i].id === packageId) {
        if (packageAmount === 0) {
          this.filteredPackagesDataWithIdAndAmount.splice(i, 1);
        } else {
          this.filteredPackagesDataWithIdAndAmount[i].amount = packageAmount;
        }
        break;
      }
    }
  }

  pay() {
    this.rest.showSpinner();

    let obj = {
      id: this.order_id,
      paid: true,
    };

    this.rest.orderStatus(obj).subscribe((res: any) => {
      this.order_arr = [];
      this.rest.hideSpinner();
      this.rest.successToast('Order Payed');
      this.getData();
    });
  }

  delivered() {
    this.rest.showSpinner();

    let obj = {
      id: this.order_id,
      delivered: true,
    };

    this.rest.orderStatus(obj).subscribe((res: any) => {
      this.order_arr = [];
      this.rest.hideSpinner();
      this.rest.successToast('Order Delivered');
      this.getData();
    });
  }

  DeleteOrder() {
    this.rest.showSpinner();
    this.rest.deleteOrder(this.order_id).subscribe((res: any) => {
      this.rest.hideSpinner();
      this.rest.successToast('Order Deleted');
      this.routing.navigateByUrl('main/orders');
    });
  }

  getCurrentOrderForm() {
    this.currentProductForm = new FormGroup({
      currentProductAmount: new FormControl(null),
      currentPackageAmount: new FormControl(null),
    });
  }

  getCatId(item: any) {
    this.rest.showSpinner();
    this.catName = item.name;
    this.status_prod = false;
    this.rest.cat_full_details(item.id).subscribe((res: any) => {
      this.rest.hideSpinner();
      this.status_prod = true;
      if (res?.products?.length != 0) {
        this.ifProd = true;
        this.prod_arr = res?.products;
      } else {
        this.ifProd = false;
      }
    });
  }

  selectPackageFromList(item: any) {
    this.packageName = item?.name;
    this.packageID = item?.id;
    this.ifPackage = true;
  }

  getDataForEditModel() {
    this.rest.showSpinner();
    // Get category list
    this.rest.listCatOnly().subscribe((res: any) => {
      this.cat_arr = res;
      this.rest.hideSpinner();
    });

    // Get package list
    this.rest.getPackages().subscribe((res: any) => {
      this.package_arr = res;
      this.rest.hideSpinner();
    });
  }

  choseProd(item: any) {
    this.prodID = item?.id;
    this.prodName = item?.name;
  }

  add() {
    this.arrayChoiceProduct.push({
      prodName: this.prodName,
      amount: this.Quantity,
    });
    this.arrayOfProductPackages.push({
      id: this.prodID,
      quantity: this.Quantity,
    });

    this.ifProd = false;
    this.status_prod = false;
    this.catName = '';
    this.prodName = '';
    this.Quantity = '';
  }

  addPackage() {
    this.arrayChoicePackage.push({
      packageName: this.packageName,
      amount: this.packageQuantity,
    });
    this.arrayOfPackages.push({
      id: this.packageID,
      amount: this.packageQuantity,
    });

    this.ifPackage = false;
    this.packageName = '';
    this.packageName = '';
    this.packageQuantity = '';
  }

  save() {
    this.rest.showSpinner();
    let obj = {
      id: this.order_id,
      address_id: this.clientAddressIdSelected,
      cashed: this.orderDetails?.cashed,
      products: this.arrayOfProductPackages?.length
        ? this.arrayOfProductPackages.concat(
            this.filteredProductDataWithIdAndAmount
          )
        : this.filteredProductDataWithIdAndAmount,

      packages: this.arrayOfPackages?.length
        ? this.arrayOfPackages.concat(this.filteredPackagesDataWithIdAndAmount)
        : this.filteredPackagesDataWithIdAndAmount,

      fees: this.orderFees,
      phone_number: this.phoneNoForm.get('phoneNumber').value,
    };
    this.rest.editOrder(obj).subscribe(
      (res: any) => {
        this.rest.hideSpinner();
        this.order_arr = [];
        this.getData();
        this.closePrompt();
      },
      (err: any) => {
        this.rest.hideSpinner();
      }
    );
  }

  changeAddress() {
    this.getClientAddress();
    this.showChangingAddress = true;
  }

  addNewAddress() {
    this.addNewAddressForm();
    this.showAddingNewAddressForm = true;
    this.showChangingAddress = false;
  }

  getClientAddress() {
    this.rest.showSpinner();

    this.rest.getUserAddress(this.clientIdForAddress).subscribe((res) => {
      this.rest.hideSpinner();

      this.clientAddressForUpdatingAddress = res;
    });
  }

  selectAddress(addressId: any) {
    this.clientAddressIdSelected = addressId;
  }

  addNewAddressForm() {
    this.clientAddress = new FormGroup({
      address: new FormControl('', [Validators.required]),
      governorate: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      client_id: new FormControl(this.clientIdForAddress),
    });
  }

  saveAddress() {
    this.showAddingNewAddressForm = false;
    this.showChangingAddress = true;
    this.rest.addNewAddress(this.clientAddress.value).subscribe(() => {
      this.rest.successToast('Address added successfully');
    });

    setTimeout(() => {
      this.getClientAddress();
    }, 100);
  }

  backToClientAddressList() {
    this.showAddingNewAddressForm = false;
    this.showChangingAddress = true;
  }

  //Phone number Form
  phoneNoForm: FormGroup | any;
  phoneNumberForm() {
    this.phoneNoForm = new FormGroup({
      phoneNumber: new FormControl('', [
        Validators.required,
        this.phoneNumberValidator,
      ]),
    });
  }
  //Phone number Form

  phoneNumberValidator(control: AbstractControl): ValidationErrors | null {
    const phonePattern = /^[0-9]{11}$/;
    const value = control.value;

    if (!value) {
      return null; // Allow empty value
    }

    if (phonePattern.test(value)) {
      return null; // Validation passes
    } else {
      return { invalidEmailOrPhone: true }; // Validation fails
    }
  }

  closePrompt() {
    this.showChangingAddress = false;
    this.showAddingNewAddressForm = false;
    this.arrayChoiceProduct = [];
    this.ifProd = false;
    this.status_prod = false;
    this.catName = null;
  }
}
