import { AfterViewInit, Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { ReplaySubject, takeUntil } from 'rxjs';
import { CategoriesService } from 'src/app/services/categori.service';

@Component({
  selector: 'app-archived-orders',
  templateUrl: './archived-orders.component.html',
  styleUrls: ['./archived-orders.component.css'],
})
export class ArchivedOrdersComponent implements OnInit {
  order_arr: any;
  showMessage = true;
  orders_global: any = [];

  page: number = 1;
  limit: number = 10;
  totalCount: number = 10;
  loading: boolean = false;
  allArchivedOrders: any;
  selectedRow: any;

  phoneNumberSearchKey: string | any = '';
  productNameSearchKey: string | any = '';

  clientPhoneNumberValue: string = '';
  productNameValue: string = '';

  displayedColumns: string[] = [
    '#',
    'username',
    'date',
    'packages',
    'status',
    'paid',
    'delivered',
    'totalPrice',
  ];

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(private rest: CategoriesService, private route: Router) {}

  ngOnInit() {
    this.getSessionStorageDate();
    this.rest.showSpinner();
    this.getAllArchivedOrders();
  }

  getAllArchivedOrders() {
    this.loading = true;
    this.rest
      .getFilteredOrdersService(
        this.page,
        this.limit,
        this.productNameSearchKey,
        this.phoneNumberSearchKey,
        undefined,
        true,
        true
      )
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res: any) => {
        this.rest.hideSpinner();
        this.loading = false;
        this.allArchivedOrders = res?.results;
        this.totalCount = res.total_count;
      });
  }

  changePage(e: PageEvent) {
    this.page = e.pageIndex + 1;
    this.getAllArchivedOrders();
  }

  orderDetails(id: any) {
    this.route.navigate(['main/order_details', id]);

    if (this.productNameSearchKey) {
      sessionStorage.setItem(
        'orderProductSearchKey',
        this.productNameSearchKey
      );
    }

    if (this.phoneNumberSearchKey) {
      sessionStorage.setItem(
        'orderClientPhoneNumber',
        this.phoneNumberSearchKey
      );
    }

    sessionStorage.setItem('pageNumber', this.page.toString());
  }

  phoneNumberSearch(phoneNumberSearchEvent: any) {
    this.page = 1;
    this.limit = 10;
    this.phoneNumberSearchKey = phoneNumberSearchEvent?.target?.value;
    this.getAllArchivedOrders();
  }

  productNameSearch(productNameSearchEvent: any) {
    this.page = 1;
    this.limit = 10;
    this.productNameSearchKey = productNameSearchEvent?.target?.value;
    this.getAllArchivedOrders();
  }

  onRowHovered(row: any) {
    this.selectedRow = row;
  }

  handleMouseLeave() {
    this.selectedRow = false;
  }

  getSessionStorageDate() {
    this.productNameSearchKey = sessionStorage.getItem('orderProductSearchKey');
    sessionStorage.removeItem('orderProductSearchKey');
    this.productNameValue = this.productNameSearchKey;

    this.phoneNumberSearchKey = sessionStorage.getItem(
      'orderClientPhoneNumber'
    );
    sessionStorage.removeItem('orderClientPhoneNumber');
    this.clientPhoneNumberValue = this.phoneNumberSearchKey;

    const pageNumberStored = sessionStorage.getItem('pageNumber');
    if (pageNumberStored) {
      const myRetrievedPageNumber = parseFloat(pageNumberStored);
      this.page = myRetrievedPageNumber;
    }

    sessionStorage.removeItem('pageNumber');
  }
}
