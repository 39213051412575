<section class="main">
  <div class="mb-4">
    <button
      class="me-2 btn btn-primary"
      mat-raised-button
      data-bs-toggle="modal"
      data-bs-target="#exampleModal"
    >
      Edit Blog
    </button>
    <button
      class="btn btn-danger"
      mat-raised-button
      data-bs-toggle="modal"
      data-bs-target="#exampleModalTow"
    >
      Delete Blog
    </button>
  </div>

  <h1>Blog Name : {{ blogName }}</h1>

  <div>
    <p>Content : {{ blogContent }}</p>
    <p>Steps : {{ blogSteps }}</p>
    <p>Date : {{ blogData | date : "EEEE d MMMM y " }}</p>
  </div>
  <table class="mt-5 table table-striped">
    <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Name</th>
        <th scope="col">Image</th>
        <!-- <th scope="col">Actions</th> -->
      </tr>
    </thead>
    <tbody *ngIf="!showMessage">
      <tr *ngFor="let item of blogProduct">
        <td>{{ item.id }}</td>
        <td>{{ item.name }}</td>
        <td><img style="width: 80px" src="{{ item.image[0].image_url }}" /></td>
        <!-- <td><span  style="text-decoration: underline; color: red">Delete</span></td> -->
      </tr>
    </tbody>
    <h1 *ngIf="showMessage">NO DATA FOUND</h1>
  </table>

  <hr />
  <div class="mt-4">
    <h2>Main images</h2>

    <div class="row">
      <div
        class="col-md-3 col-6 text-center"
        *ngFor="let item of mainArrImages"
      >
        <img class="img-fluid" src="{{ item.image_url }}" />
        <br />
        <button (click)="DeleteImage(item.id)" class="mt-2 btn btn-danger">
          Delete
        </button>
      </div>
    </div>
  </div>

  <hr />

  <div *ngIf="videosArr.length != 0">
    <h2>Videos</h2>

    <div class="row">
      <div class="col-12 col-md-6 text-center" *ngFor="let item of videosArr">
        <video style="width: 100%" controls>
          <source src="{{ item.image_url }}" type="video/mp4" />
          <source src="{{ item.image_url }}" type="video/ogg" />
        </video>

        <button (click)="DeleteImage(item.id)" class="mt-2 btn btn-danger">
          Delete
        </button>
      </div>
    </div>
  </div>
</section>

<div
  class="modal fade"
  id="exampleModal"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="mt-2">
          <label>Blog Name</label>
          <input [(ngModel)]="title" type="text" class="form-control" />
        </div>
        <div class="mt-2">
          <label>Content</label>
          <input [(ngModel)]="content" type="text" class="form-control" />
        </div>
        <div class="mt-2">
          <label>Steps</label>
          <input [(ngModel)]="steps" type="text" class="form-control" />
        </div>
        <label>Blog Image</label>
        <input
          multiple
          (change)="uploadFile($event, 'main')"
          type="file"
          class="form-control"
        />

        <label>Blog Video</label>
        <input
          multiple
          (change)="uploadFile($event, 'video')"
          type="file"
          class="form-control"
        />
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Close
        </button>
        <button
          data-bs-dismiss="modal"
          type="button"
          (click)="editBlog()"
          class="btn btn-primary"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="exampleModalTow"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">Are you sure to Delete ({{ blogName }})</div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Close
        </button>
        <button
          (click)="DeleteBlog()"
          data-bs-dismiss="modal"
          type="button"
          class="btn btn-primary"
        >
          Confirm
        </button>
      </div>
    </div>
  </div>
</div>
