<section class="main">
  <div class="container table-responsive">
    <h1>Order Requests</h1>
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Name</th>
          <th scope="col">Products</th>
          <th scope="col">Date</th>
          <th scope="col">Client Email</th>
          <th scope="col">Url</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody *ngIf="!showMessage">
        <tr style="cursor: pointer" *ngFor="let item of orders_arr">
          <td>{{ item.id }}</td>
          <td>{{ item.name }}</td>
          <td><img [src]="item.image[0]" /></td>
          <td>{{ item.requested_date | date : "EEEE d MMMM y " }}</td>
          <td *ngIf="!item.client">test@test</td>
          <td *ngIf="item.client">{{ item.client.email }}</td>
          <td>
            <a target="_blank" href="{{ item.URL }}">Hyper Link</a>
          </td>
          <td style="color: red">Delete</td>
        </tr>
      </tbody>
      <h1 *ngIf="showMessage">NO DATA FOUND</h1>
    </table>
  </div>
</section>
