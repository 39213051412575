import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoriesService } from 'src/app/services/categori.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-cat-details',
  templateUrl: './cat-details.component.html',
  styleUrls: ['./cat-details.component.css'],
})
export class CatDetailsComponent implements OnInit {
  cat_id: any;
  formStatus = false;
  showProduct = false;
  cat_obj = {
    name: '',
    image: '',
    products: [],
  };
  product_arr: any = [];
  cat_name = '';
  image_file: any = [];
  productForm: FormGroup | any;
  showUpload = false;
  product_id: any;
  constructor(
    private route: ActivatedRoute,
    private routing: Router,
    private _sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private rest: CategoriesService
  ) {}

  ngOnInit() {
    this.rest.showSpinner();
    this.productForm = this.formBuilder.group({
      name: ['', Validators.required],
      price: ['', Validators.required],
      description: ['', Validators.required],
      quantity: ['', Validators.required],
    });
    this.cat_id = this.route.snapshot.paramMap.get('id');
    this.getData();
  }

  getData() {
    this.image_file = [];
    this.rest.cat_full_details(this.cat_id).subscribe((res: any) => {
      // if(res.image != null){
      //   res.image.image = this._sanitizer.bypassSecurityTrustUrl(`data:image/png;base64,`+ res.image.image);
      //   this.cat_obj.image = res.image.image
      // }else {
      //   this.cat_obj.image = "assets/images/rk.jpeg"
      // }
      this.cat_obj.name = res.name;
      this.cat_name = this.cat_obj.name;

      res.products.forEach((element: any) => {
        if (element.images.length != 0) {
          element.images[0].image_url = `${environment.apiUrl}/images/?id=${element.images[0].image_url}`;
        } else {
          element.images[0] = {
            image_url: 'assets/images/rk.jpeg',
          };
        }
      });

      this.product_arr = res.products;

      if (this.product_arr.length != 0) {
        this.showProduct = true;
      } else {
        this.showProduct = false;
      }
      this.rest.hideSpinner();
    });
  }

  addProduct() {
    let obj = this.productForm.value;
    if (this.productForm.valid) {
      this.formStatus = false;
      obj.category_id = this.cat_id;
      this.rest.addProduct(obj).subscribe(
        (res: any) => {
          this.product_id = res;
          this.showUpload = true;
          this.productForm.reset();
        },
        (err: any) => {
          this.rest.errorToaster(err.error.message);
        }
      );
    } else {
      this.formStatus = true;
    }
  }
  image_file_cat: any;
  uploadFileCat(e: any) {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      this.image_file_cat = e.target.files[0];
    }
  }
  addCategoryImage() {
    if (this.image_file_cat != undefined) {
      this.rest
        .upload_cat_image(this.cat_id, this.image_file_cat)
        .then((response: any) => response.text())
        .then((result: any) => {
          this.rest.successToast('Category image updated Successfully');
          this.getData();
        })
        .catch((error: any) => {
          this.rest.errorToaster(
            'something wrong happened when edit image please try again'
          );
        });
    } else {
      this.getData();
    }
  }

  uploadFile(e: any, type: any) {
    let dummy: any = e.target.files;
    for (let i = 0; i < dummy.length; i++) {
      this.image_file.push({
        name: type + [i],
        value: dummy[i],
      });
    }

  }

  addProductImage() {
    this.rest.showSpinner();
    this.rest
      .upload_product_image(this.product_id, this.image_file)
      .then((response: any) => response.text())
      .then((result: any) => {
        this.image_file = [];
        this.getData();
        this.rest.successToast('Product Added Successfully');
        this.rest.hideSpinner();
        this.showUpload = false;
      })
      .catch((error: any) => {
        this.rest.errorToaster('something wrong happened please reload page');
        this.rest.hideSpinner();
      });
  }

  editCat() {
    this.rest.showSpinner();
    this.cat_id = +this.cat_id;
    let obj = {
      id: this.cat_id,
      name: this.cat_name,
    };
    this.rest.editCat(obj).subscribe(
      (res: any) => {
        this.rest.hideSpinner();
        this.getData();
        this.rest.successToast('Category name updated Successfully');
      },
      (err: any) => {
        this.rest.errorToaster(
          'something wrong happened When Edit Category Name pleas try again'
        );
        this.rest.hideSpinner();
      }
    );
    this.addCategoryImage();
  }

  deleteCategory() {
    this.rest.showSpinner();
    this.rest
      .deleteCategory(this.cat_id)
      .then((response) => response.text())
      .then((result) => {
        this.rest.hideSpinner();
        this.routing.navigate(['main/categoris']);
      })
      .catch((error) => {
        this.rest.errorToaster('something wrong happened please try again');
        this.rest.hideSpinner();
      });
  }

  productDetails(id: any) {
    this.routing.navigate(['main/product_details', id]);
  }

  search(data: any) {
    this.rest
      .searchProd(data.target.value, this.cat_id)
      .subscribe((res: any) => {
        res.forEach((element: any) => {
          element.images = [
            {
              image_url: `${environment.apiUrl}/images/?id=${element.image[0]}`,
            },
          ];
        });

        this.product_arr = res;

        if (this.product_arr.length != 0) {
          this.showProduct = true;
        } else {
          this.showProduct = false;
        }
      });
  }
}
