import { Component, OnInit } from '@angular/core';
import { CategoriesService } from 'src/app/services/categori.service';

@Component({
  selector: 'app-promo-details',
  templateUrl: './promo-details.component.html',
  styleUrls: ['./promo-details.component.css'],
})
export class PromoDetailsComponent implements OnInit {
  promoDetails: any;
  promoName: any = '';
  promoProud: any = [];
  constructor(private rest: CategoriesService) {}

  ngOnInit() {
    this.promoDetails = localStorage.getItem('promo');
    this.promoDetails = JSON.parse(this.promoDetails);
    this.promoName = this.promoDetails.promo_code;
    this.getData();
  }

  getData() {
    this.rest.retrievePromo(this.promoName).subscribe((res: any) => {
      this.promoProud = this.promoDetails.orders;
    });
  }
}
